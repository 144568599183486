import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CreateRenameModal, { CreateRenameModalAction } from '../FolderManagement/CreateRenameModal';
import { useUserFilesContext } from './UserFilesProvider';
import SpatialDataUploadModal from './SpatialDataUploadModal';
import DeleteConfirmationModal, { DeletedType } from '../FolderManagement/DeleteConfirmationModal';
import { ReactQueryKeys } from '../../../queries/queryKeys';
import { updateUserFile } from '../../../api/userFileService';
import { FOLDER_COUNT_PARAM, UserFile } from '../../../types/userFiles';

const UserFileModals = () => {
    const {
        showRenameModal,
        setShowRenameModal,
        showDeleteConfirmationModal,
        setShowDeleteConfirmationModal,
        setShowUploadModal,
        showUploadModal,
        setPage,
        setSelectedKeys,
        fileListQueryParams,
    } = useUserFilesContext();
    const queryClient = useQueryClient();

    const { mutate: updateFile } = useMutation({
        mutationFn: ({ fileID, fileName }: { fileID: string; fileName: string }) => updateUserFile(fileID, fileName),
        onSuccess: (_, variables) => {
            queryClient.setQueriesData<{ data: UserFile[] }>(
                ReactQueryKeys.userFileList(fileListQueryParams),
                (userFile) => {
                    if (userFile) {
                        const updatedFileIndex = userFile?.data?.findIndex((file) => file.id === variables.fileID);
                        if (updatedFileIndex >= 0) {
                            const newData = [...userFile.data];
                            const newUserFileItem = {
                                ...newData[updatedFileIndex],
                                name: variables.fileName,
                            };
                            newData.splice(updatedFileIndex, 1, newUserFileItem);
                            return { ...userFile, data: newData };
                        }
                        return userFile;
                    }
                    return userFile;
                }
            );
        },
    });

    return (
        <>
            <SpatialDataUploadModal visible={showUploadModal} onClose={() => setShowUploadModal(false)} />
            <CreateRenameModal
                action={CreateRenameModalAction.renameUserFile}
                visible={!!showRenameModal}
                onCancel={() => {
                    setShowRenameModal(undefined);
                }}
                currentName={showRenameModal?.currentName}
                id={showRenameModal?.id}
                onRename={(id, name) => {
                    updateFile({ fileID: id, fileName: name });
                }}
            />
            <DeleteConfirmationModal
                visible={!!showDeleteConfirmationModal}
                onCancel={() => {
                    setShowDeleteConfirmationModal(undefined);
                }}
                deletedType={DeletedType.userFile}
                ids={showDeleteConfirmationModal?.ids ?? []}
                name={showDeleteConfirmationModal?.name}
                onDelete={() => {
                    setPage((prevState) => {
                        if (prevState > 1) {
                            // update folder count
                            queryClient.invalidateQueries({
                                queryKey: ReactQueryKeys.userFileList(FOLDER_COUNT_PARAM),
                            });
                            // changing page will cause react query to pull details again, with the new page
                            return prevState - 1;
                        }
                        // updates list and folder count
                        queryClient.invalidateQueries({ queryKey: [...ReactQueryKeys.userFile, 'list'] });
                        return prevState;
                    });
                    setSelectedKeys([]);
                }}
            />
        </>
    );
};

export default UserFileModals;
