import { Log } from './logs';
import { EquotipProbeInfo, GPRProbeInfo } from './probe';
import {
    DateTimeString,
    Fingerprint,
    LangKey,
    ProductCode,
    ProductModel,
    ScanType,
    ShortLangKey,
    UnixMicroseconds,
    UUID,
} from './proceq';
import { stringEnumToArray } from '../utils/generalUtils';
import { FormatIDs } from './reactIntl';
import { SPATIAL_DATA_FILE_TYPE } from './userFiles';
import { FilterParams, OrderDir } from '../api/measurementService';

export type FileType = 'measurement' | 'verification' | CustomCurveFileType.dgscc;

export type Measurement = {
    id: UUID;
    fID: string;
    contractID: string;
    name: string;
    type: ScanType | FileType;
    productModel: ProductModel;
    productType: string;
    contractType: UUID;
    probeinfo: GPRProbeInfo | EquotipProbeInfo;
    content: any;
    dongleHID: string;
    sharing: number;
    permission: string;
    flagged: boolean;
    imported: boolean;
    trashed: boolean;
    fp: string;
    clientCreated: UnixMicroseconds;
    clientUpdated: UnixMicroseconds;
    created: UnixMicroseconds;
    updated: UnixMicroseconds;
    isDemoFile: boolean;
    size?: number;
    sizeInTimeDomain?: number;
};

export interface MeasurementListItem extends Measurement {
    settings: MeasurementSettings[];
    isImport?: boolean;
    isCreate?: boolean;
    importStatus?: ImportMeasurementStatus;
    timestamp?: number;
}

export interface EquotipSeries {
    angleCorrection: number;
    excluded: boolean;
    index: number;
    isOutlier: boolean;
    isNoConversion: boolean;
    primaryValue: number;
    secondaryValue: number;
}

export type EquotipMeasurementContent = {
    series: EquotipSeries[];
    stats: {
        average: number;
        maximum: number;
        minimum: number;
        range: number;
        readings: number;
        relativeSpan: number;
        stdDev: number;
    };
};

export type GLMMeasurementContent = {
    series: {
        valueMaxHaze: number;
        valueStd1: number;
        valueMax2: number;
        valueMin0: number;
        valueStd2: number;
        valueA0: number;
        valueActual0: number;
        valueStd0: number;
        valueMin2: number;
        valueHaze: number;
        valueMean0: number;
        measureMode: number;
        valueA1: number;
        valueActual1: number;
        valueMax0: number;
        valueMin1: number;
        valueMean2: number;
        statsCount: number;
        valueMinHaze: number;
        valueMeanHaze: number;
        valueA2: number;
        valueActual2: number;
        valueStdHaze: number;
        index: number;
        valueMax1: number;
        valueMean1: number;
    }[];
};

export type Folder = {
    id: UUID;
    fp: Fingerprint;
    type: string;
    name: string;
    trashed: boolean;
    deletable: boolean;
    restorable?: boolean;
    itemCount: number;
    created: UnixMicroseconds;
    updated: UnixMicroseconds;
    clientCreated: UnixMicroseconds;
    clientUpdated: UnixMicroseconds;
};

export type MeasurementReading = {
    id: UUID;
    mID: string;
    aID: string;
    fp: Fingerprint;
    checksum: string;
    content: any;
    type: string;
    created: UnixMicroseconds;
    updated: UnixMicroseconds;
    sequenceNo: number;
    measuredDistanceMeter: number;
};

export type MeasurementSettings = {
    content: any;
    created: UnixMicroseconds;
    fp: Fingerprint;
    id: UUID;
    mID: UUID;
    updated: UnixMicroseconds;
};

export type EquotipMeasurementSettingsContent = {
    excluded: number[];
    materialId: number;
    primary: Scale;
    secondary: Scale;
    standardId: number;
    triggerLoadId: number;
    testBlock: {
        // Verification
        name: string;
        id: string;
        margin: number;
        serialNumber: number;
        value: number;
    };
    customMaterial: Object;
    probeSeriesCounter?: number; // Schmidt only
    probeSeriesId?: number; // Schmidt only
};

type Scale = {
    limits: {
        low: number;
        high: number;
    };
    scaleId: number;
    conversionCurveId: number;
    formFactorId: number;
    probeSeriesCounter: number;
    probeSeriesId: number;
    carbonationDepth: number;
    strengthId: number;
    surfaceCorrectionId: number;
    surfaceConditionId: number;
    age: number;
};

export type Attachment = {
    id: UUID;
    fID: string;
    mID: string;
    rID: string;
    rType: string;
    type: string;
    fileNames: string[];
    zippedFileSize: number;
    created: UnixMicroseconds;
    updated: UnixMicroseconds;
};

export type Dongle = {
    id: number;
    hardwareID: string;
    serialNo: string;
    productType: ProductCode;
    ftpTimeStamp: string;
    created: DateTimeString;
    updated: DateTimeString;
};

export type ImportResponse = {
    name: string;
    oldID: UUID;
    newID: UUID;
    type: ScanType;
};

export type AnalyticsFlags = 'trashed' | 'deleted' | 'alive';
export type AnalyticsMetrics = 'common' | 'geo' | 'measurements' | 'storage';
export type CommonAnalytics = {
    mLastCreated: UnixMicroseconds;
    mLastUpdated: UnixMicroseconds;
    nAudios: number;
    nCreatedMeasurements: number;
    nFolders: number;
    nImportedFromOthers: number;
    nMFlagged: number;
    nMShared: number;
    nMTrashed: number;
    nPhotos: number;
    nSnapshot: number;
    nTextLogs: number;
    nTotalImported: number;
    nTotalFiles: number;
    nMDeleted: number;
};
export type GPRMeasurementAnalytics = {
    areaScan: ProductMeasurementAnalytics;
    lineScan: ProductMeasurementAnalytics;
};
export type ProductMeasurementAnalytics = {
    measurementCount: number;
    rawDataStorage: number;
    photoCount: number;
    audioCount: number;
    photoStorage: number;
    audioStorage: number;
    snpashotCSVCount: number;
    snapshotAndCSVStorage: number;
    snapshotCount: number;
    snapshotStorage: number;
    totalStorage: number;
};
export type PunditMeasurementAnalytics = {
    stripeScan: ProductMeasurementAnalytics;
    lineScan: ProductMeasurementAnalytics;
};
export type FDLMeasurementAnalytics = {
    conventionalScan: ProductMeasurementAnalytics;
    corrosionMap: ProductMeasurementAnalytics;
};
export type EquotipMeasurementAnalytics = {
    nMeasurements: number;
    nVerifications: number;
    conversionScales: {
        id: number;
        value: number;
    }[];
    conversionStandards: {
        id: number;
        value: number;
    }[];
    impactsWithTime: {
        key: string;
        value: number;
    }[];
    materialGroups: {
        id: number;
        value: number;
    }[];
    nExcluded: number;
    nImpacts: number;
    nOutOfLimit: number;
};
export type SchmidtMeasurementAnalytics = EquotipMeasurementAnalytics;

type StorageBreakdownAnalytics = {
    measurementRawDataStorage: number;
    imageStorage: number;
    audioStorage: number;
    totalStorage: number;
};

export type StorageAnalytics = {
    [key: string]: StorageBreakdownAnalytics;
};
export type MeasurementAnalytics =
    | GPRMeasurementAnalytics
    | PunditMeasurementAnalytics
    | EquotipMeasurementAnalytics
    | SchmidtMeasurementAnalytics;

export type RegionalAnalytics = {
    name: string;
    mid: string;
    uid: string;
    lat: number;
    lon: number;
};

export type MeasurementSummary = {
    numberCreated: number;
    numberDeleted: number;
    numberStorageUsage: number;
};

export type UserProducts = {
    [x: string]: MeasurementSummary;
};

export type MeasurementFileType = 'all' | 'LineScan' | 'AreaScan' | 'StripeScan' | 'measurement' | 'verification';

export type FilterProp = {
    filter?: string;
    range?: true;
    globalRegister?: string[];
    filterOps?: { text: string; value: any }[];
};

export type MeasurementObject = {
    id: UUID;
    mID: string;
    rID: string;
    fp: Fingerprint;
    content: any;
    type: string;
    created: UnixMicroseconds;
    updated: UnixMicroseconds;
    sequenceNo: number;
};

export type MeasurementFullData = {
    productFamily: ProductCode;
    uniqueName?: string;
    folder: Folder;
    measurement: Measurement;
    readings: MeasurementReading[];
    settings: MeasurementSettings[];
    objects: MeasurementObject[];
    logs: Log[];
    attachments: Attachment[];
};

export type FDLSetupFile = {
    name: string;
    measuringMode: number;
    probeAngle: number;
    probeType: string;
    frequency: number;
    voltage: number;
    damping: number;
    dgsAttenuationCoeffCalib: number;
    dgsDeltaVk: number;
    dgsErs: number;
    pulseWidth: {
        mode: string;
        value: number;
    };
    prf: {
        mode: string;
        value: number;
    };
    filter: {
        option: string;
    };
    spatialAveraging: string;
    analogueFilter: string;
    digitalFilter: string;
    unit?: string;
};

export interface FDLDGSIndicationInfo {
    // DGS calibration point
    calibratedDistance?: number; // mm
    calibratedAmplitude?: number; // dB

    // Sized defect
    defectDistance?: number; // mm
    defectAmplitude?: number; // dB
    defectErs?: number; // mm
    defectDbToCurve?: number; // dB

    // Sized defect 2
    defect2Distance?: number; // mm
    defect2Amplitude?: number; // dB
    defect2Ers?: number; // mm
    defect2DbToCurve?: number; // dB
}

export type Locale = {
    shortlang: ShortLangKey;
    lang: LangKey;
    messages: {
        [id: string]: string;
    };
    antdLocaleProvider: Object;
};

export type GLMSettingsContent = {
    angleBinList: number[];
    angleBin: number;
    interval?: number;
    limitsItems: {
        high: number;
        low: number;
    }[];
    measureMode: number;
    probeSeriesCounter: number;
    probeSeriesId: number;
    unit: number;
};

type Line = {
    dimension: 'X' | 'Y';
    sequence: number;
};

export enum LineStatusDirection {
    up,
    right,
    down,
    left,
}

export type LineStatus = {
    measuredDistanceMeter: number;
    numberOfAScans?: 148;
    position: Line;
    scanId?: string;
    direction: LineStatusDirection;
};

export type GprContent = {
    areaScanStatus: {
        completedLines: Line[];
        currentLine: Line;
        lineStatus: LineStatus;
    };
    freePathStatus?: {
        measuredDistanceMeter: number;
    };
    info: {
        author: string;
        contractID: string;
        email: string;
        fileID: string;
        fileName: string;
        hardwareVersion: string;
        macAddress: string;
        model: string;
        probeSerialNumber: string;
        scanMode: ScanType;
        snapshotCount: number;
        version: string;
    };
    tagMetaData?: {
        calibratedObjectId: UUID;
        markerAudioIndex: number;
        markerTextIndex: number;
        objectIndex: number;
    };
};

export type PunditContent = GprContent;

export type Statistics = {
    avg: number;
    count: number;
    max: number;
    min: number;
    range: number;
    relSpan: number;
    stdev: number;
};

export type EquotipContent = {
    properties: {
        app: {
            platform: string;
            version: string;
        };
        contractExpirationDate: DateTimeString;
        location: Location;
        verificationResult: 'passed' | 'failed';
    };
    statistics: {
        primary: Statistics;
        secondary: Statistics;
    };
};

export type SchmidtContent = EquotipContent;

export const htmlViewExportFolder = 'html_view_export';

export enum SystemFolderType {
    All = 'all',
    Default = 'default',
    Import = 'import',
    Flagged = 'flagged',
    Demo = 'demo',
    Archived = 'archived',
    Trash = 'trash',
    VerificationDefault = 'verification_default',
    DGSDefault = 'dgs_default',
}

export const SystemFolderTypes = stringEnumToArray(SystemFolderType);
export const ArchivedSystemFolderTypes = [
    SystemFolderType.All,
    SystemFolderType.Default,
    SystemFolderType.Import,
    SystemFolderType.Flagged,
];
export const SystemFolderTypesSet = new Set<string>(SystemFolderTypes);

export enum SystemFolderID {
    All = 'all',
    Imported = 'imported',
    Flagged = 'flagged',
    Demo = 'demo',
    Archived = 'archived',
    Trashed = 'trashed',
}

export const NON_FILE_MANAGEMENT_FOLDERS = new Set([SystemFolderID.Demo, SystemFolderID.Trashed]);

export enum ViewType {
    Active = 'active',
    Archived = 'archived',
}

export enum CustomCurveFileType {
    dgscc = 'dgscc',
}

export enum BluetoothFileType {
    VerificationData = 'verification',
    CustomMaterial = 'customMaterial',
}

export enum PunditGridScanDataOfInterestType {
    BackwallDepth = 'Backwall Depth',
    PulseVelocity = 'Pulse Velocity',
}

export enum TagObjectType {
    Backwall = 'Backwall',
    NonMetallic = 'Non Metallic',
    Rebar = 'Rebar',
    Void = 'Void',
    LiveWire = 'Live Wire',
    UserDefined = 'User Defined',
    Velocity = 'Velocity',
    PulseVelocity = 'Pulse Velocity',
    TendonDuctVoid = 'Tendon Duct Void',
}

export type ImportMeasurementStatus = 'pending' | 'success' | 'failure';

export interface CreateMeasurementData {
    name: string;
    id: string;
}

export interface ImportMeasurementData {
    name: string;
    oldID: string;
    newID: string;
    type?: ScanType | FileType;
    status: ImportMeasurementStatus;
    timestamp: number;
}

export interface PunditImpactSpot {
    name: string;
    aScanIDs: string[];
    bestScanID?: string;
    fftPeak?: number;
    id: string;
    isFFTPeakAutoDetectionEnabled: boolean;
}

export const ROCK_SCHMIDT_PROBE = 'RS8000';
export const SILVER_SCHMIDT_PROBE = 'SSL';

const ASTM_IMPERIAL_DIAMETER_REBAR_SIZE: Record<string, string> = {
    '6': '#2',
    '10': '#3',
    '13': '#4',
    '16': '#5',
    '19': '#6',
    '22': '#7',
    '25': '#8',
    '29': '#9',
    '32': '#10',
    '35': '#11',
    '38': '#12',
};

export const getASTMImperialDiameterRebarSize = (value: string) => {
    const rebarSize = ASTM_IMPERIAL_DIAMETER_REBAR_SIZE[value];
    if (rebarSize) {
        return rebarSize;
    }

    // get nearest valid value if it's not in the map
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue <= 0 || numericValue > 40) {
        return '0';
    }
    const validValues = Object.keys(ASTM_IMPERIAL_DIAMETER_REBAR_SIZE).map((key) => +key);
    const nearestValidValue = validValues.reduce((prev, curr) =>
        Math.abs(curr - numericValue) < Math.abs(prev - numericValue) ? curr : prev
    );
    return ASTM_IMPERIAL_DIAMETER_REBAR_SIZE[nearestValidValue];
};

export enum ProfometerUnit {
    metric = 'metric',
    imperial = 'imperial',
    imperialDiameter = 'imperialDiameter',
    metricJapanese = 'metricJapanese',
}

export const PROFOMETER_METRIC_UNITS = new Set([
    ProfometerUnit.metric,
    ProfometerUnit.metricJapanese,
    ProfometerUnit.imperialDiameter,
]);

export const FDL_SCAN_TYPE: Partial<Record<ScanType, FormatIDs>> = {
    [ScanType.Conventional]: 'App.HTML.PUNDIT.MeasurementPresets.AScan',
    [ScanType.BScan]: 'App.HTML.FDL.MeasurementPresets.PresentationMode.BScan',
    [ScanType.CorrosionMap]: 'App.HTML.FDL.MeasurementPresets.PresentationMode.GridScan',
};

export enum BScanPresentationMode {
    crossSectional = 'crossSectional',
    singleValue = 'singleValue',
    thicknessProfile = 'thicknessProfile',
}

export const SHARED_LINK_CURVE_TYPE = 'dgscc';

export const FDL_METRIC_UNITS = new Set(['metric', 'time', 'µs']);

export interface SmoothenedCurvedInfo {
    csv: string;
    yaml: string;
    colorPlotImg: string;
    bwPlotImg: string;
}

export const RESOURCE_ALREADY_EXISTS_ERROR = 'ResourceAlreadyExists';

export enum PunditDisplayedVelocity {
    ESTIMATE_P_WAVE = 'Estimate P-Wave',
    S_WAVE = 'S-Wave',
}

export enum FlawDetectorComponentType {
    flatSurface = 'flatSurface',
    curvedSurface = 'curvedSurface',
}

export enum ProbeTypeID {
    EquotipD, // 0: EQUOTIP D
    EquotipUCI, // 1: EQUOTIP UCI
    SchmidtL, // 2: SCHMIDT L
    SchmidtN, // 3: SCHMIDT N
    SilverSchmidtL, // 4: SILVER_SCHMIDT L
    SilverSchmidtN, // 5: SILVER_SCHMIDT N
}

export const DEFAULT_PAGE_SIZE = 20;

export enum DataViewMode {
    'Logbook',
    'JSON',
    'Attachments',
}

export const productDataCategoryRoutes = stringEnumToArray({
    ...BluetoothFileType,
    ...CustomCurveFileType,
    SPATIAL_DATA_FILE_TYPE,
});

export interface OrderConfig {
    orderBy?: string;
    orderDir?: OrderDir;
}

export const defaultOrderConfig: OrderConfig = {
    orderBy: 'clientUpdated',
    orderDir: OrderDir.DESC,
};

export interface FilterConfig extends FilterParams {
    category: string;
}

export enum NonEditableType {
    move = 1,
    edit,
    editFolder,
    archiveFolder,
    restoreFolder,
}

export interface NonEditableMeasurements {
    allInvalid: boolean;
    measurementIds: string[];
    nonEditableType?: NonEditableType;
}

export const DEFAULT_DATA_TYPE = 'measurement';

export type DataType =
    | typeof DEFAULT_DATA_TYPE
    | BluetoothFileType.VerificationData
    | BluetoothFileType.CustomMaterial
    | CustomCurveFileType.dgscc
    | typeof SPATIAL_DATA_FILE_TYPE;
