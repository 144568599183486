import AWS from 'aws-sdk';
import * as Sentry from '@sentry/browser';
import { getApiURL } from './ApiURL';
import { thunkGet } from './apiService';
import { store } from '../store';
import logger from '../logging/logger';

export interface WebsocketDetails {
    credentials: {
        AccessKeyId: string;
        Expiration: string;
        SecretAccessKey: string;
        SessionToken: string;
    };
    websocketsUrl: string;
}

export interface AppstreamAwsDetails extends WebsocketDetails {
    streamingURL: string;
    awsRegion: string;
    frontendBucket: string;
    roomId: string;
    userId: number;
    s3: AWS.S3;
    eagleId: number;
    appId?: string;
    sessionId?: string;
}

const reduxStore = store.getState();

export const getAppstreamDetails = async (errorHandler: (isStandaloneActive?: boolean) => void) => {
    const url = getApiURL('APPSTREAM_DETAILS').replace(
        '{baseURL}',
        reduxStore.config.appsStatus.GPR_INSIGHTS?.url ?? ''
    );
    try {
        const { response } = await thunkGet<AppstreamAwsDetails>(url, {
            injectToken: true,
        });
        AWS.config.credentials = new AWS.Credentials({
            accessKeyId: response.credentials.AccessKeyId,
            secretAccessKey: response.credentials.SecretAccessKey,
            sessionToken: response.credentials.SessionToken,
        });

        AWS.config.region = response.awsRegion;
        response.s3 = new AWS.S3({ apiVersion: '2006-03-01', params: { Bucket: response.frontendBucket } });
        response.eagleId = response.userId;
        return response;
    } catch (e: any) {
        if (e?.response?.data?.statusCode === 403 && e?.response?.data?.message === 'DESKTOP_SESSION_RUNNING') {
            logger.log(`api | desktop session running:${e}`);
            errorHandler(true);
        } else {
            logger.error(`api | error obtaining appstream details:${e}`);
            Sentry.captureException(e);
            errorHandler();
        }
        return undefined;
    }
};

export const setAppstreamSessionFinished = async () => {
    const url = getApiURL('APPSTREAM_SESSION_FINISHED').replace(
        '{baseURL}',
        reduxStore.config.appsStatus.GPR_INSIGHTS?.url ?? ''
    );
    try {
        await thunkGet(url, {
            injectToken: true,
        });
    } catch (e) {
        logger.error(`api | error setting appstream session finished:${e}`);
        Sentry.captureException(e);
    }
};

export const refreshWebsocketToken = async (errorHandler: () => void) => {
    const url = getApiURL('REFRESH_WEBSOCKET_TOKEN').replace(
        '{baseURL}',
        reduxStore.config.appsStatus.GPR_INSIGHTS?.url ?? ''
    );
    try {
        const { response } = await thunkGet<WebsocketDetails>(url, {
            injectToken: true,
        });
        AWS.config.credentials = new AWS.Credentials({
            accessKeyId: response.credentials.AccessKeyId,
            secretAccessKey: response.credentials.SecretAccessKey,
            sessionToken: response.credentials.SessionToken,
        });
        return response;
    } catch (e) {
        logger.error(`api | error obtaining websocket refresh token:${e}`);
        Sentry.captureException(e);
        errorHandler();
        return undefined;
    }
};
