import React from 'react';
import { SnapshotEntry } from './SnapshotTypes';
import styles from './SnapshotsViewer.styl';
import analytics from '../../../../../analytics/firebaseAnalytics';
import { SnapshotAction } from '../../../../../analytics/analyticsConstants';
import useSnapshotInfo from '../../../../../hooks/useSnapshotInfo';
import FormattedMessage from '../../../../../localization/FormatMessage';
import Tooltip from '../../../../shared/Tooltip';

const SnapshotInformation: React.FunctionComponent<{
    entry: SnapshotEntry;
    product?: string;
    allowDownload?: boolean;
    containerClassName?: string;
    useDownloadIcon?: boolean;
}> = ({ entry, product, allowDownload = true, containerClassName, useDownloadIcon }) => {
    const { title, initials, downloadComponent } = useSnapshotInfo({
        entry,
        onDownload: () => analytics.logDownloadSnapshot(SnapshotAction.detail, product),
        useDownloadIcon,
    });

    return (
        <div className={containerClassName ?? styles.snapshot_information}>
            <div>
                <div className={styles.subtitle1}>{title}</div>
                <div className={styles.caption}>{initials}</div>
            </div>
            <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                {allowDownload && (
                    <Tooltip title={useDownloadIcon ? <FormattedMessage id="App.Download" /> : undefined}>
                        <div className={styles.actions}>{downloadComponent}</div>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default SnapshotInformation;
