import { convertAndDisplay } from './conversionRates';
import { ScanType } from '../../../../types/proceq';

const getPMSpotScanRebarSpacing = (isMetric: boolean, unitId: string, value?: number) => {
    // saved as mm in json
    const valueInMeters = (value ?? 1) / 1000;

    const { convertFunction } = convertAndDisplay(unitId, ScanType.SpotScan, isMetric, true);
    return convertFunction(valueInMeters) ?? '-';
};

export default getPMSpotScanRebarSpacing;
