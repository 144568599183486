import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import FormattedMessage from '../../localization/FormatMessage';
import { ExportFormat, exportMeasurements, PDFExtendedFileType } from '../../api/utilsService';
import { getFileNameFromContentDisposition } from '../../utils/fileUtils';
import StyledModal from '../shared/StyledModal';
import { useMeasurementExportContext } from './MeasurementExportProvider';
import styles from './MeasurementExportModal.styl';
import { bluetoothProductFamilySet, ProductCode } from '../../types/proceq';
import { formatConfig, languageTitleMap, longRunningExcludedFormat } from './formatOptionConfig';
import { exportReleasedLanguages } from '../../localization';
import AnalyticsButton from '../AnalyticsComponents/Button';
import analytics from '../../analytics/firebaseAnalytics';
import { ExportAction, MeasurementPage } from '../../analytics/analyticsConstants';
import { logExportUserAction } from '../../api/analyticsEventService';
import InvalidShareExportModal, { InvalidShareExportType } from '../DataView/DataViewModal/InvalidShareExportModal';

interface MeasurementExportProps {
    page: MeasurementPage;
}

export const checkLRJConditions = (
    product: ProductCode,
    mIDs: string[],
    format: string,
    isLongRunningJobEnabled: boolean
) => {
    return (
        format.toLowerCase() === ExportFormat.HTML ||
        (product === ProductCode.GPR_SOIL &&
            isLongRunningJobEnabled &&
            !longRunningExcludedFormat.some((entry) => entry === format)) ||
        (product === ProductCode.GPR && format.toLowerCase() === ExportFormat.SEGY) ||
        ([ProductCode.PUNDIT, ProductCode.FDL].includes(product) && format.toLowerCase() === ExportFormat.RAW)
    );
};

export const MeasurementExport: React.FunctionComponent<MeasurementExportProps> = (props) => {
    const { page } = props;
    const { exportConfig, setExportConfig, localisedExportOptions, isVerification, isLongRunningJobEnable } =
        useMeasurementExportContext();
    const { product, mIDs, showModal } = exportConfig;
    const [isLoading, setIsLoading] = useState(false);
    const [showInvalidModal, setShowInvalidModal] = useState(false);
    const [invalidIDs, setInvalidIDs] = useState<string[]>([]);
    const [formatOption, setFormatOption] = useState<ExportFormat | PDFExtendedFileType>();

    const { locale } = useIntl();
    const [exportLanguage, setExportLanguage] = useState(locale);

    const isBluetoothProduct = product && bluetoothProductFamilySet.has(product);

    const handleExport = useCallback(
        async (validIDs?: string[]) => {
            if (!formatOption || !product || !mIDs) {
                return;
            }
            if (!validIDs && invalidIDs.length > 0) {
                setShowInvalidModal(true);
                setExportConfig((prevState) => {
                    return { ...prevState, showModal: false };
                });
                return;
            }
            const format = formatConfig[formatOption].format;
            const fileType = formatConfig[formatOption].fileType || (isVerification ? 'verification' : 'measurement');
            const exportAsURL = checkLRJConditions(product, mIDs, format, isLongRunningJobEnable);
            const measurementIDs = validIDs ?? mIDs;

            try {
                setIsLoading(true);
                const response = await exportMeasurements({
                    product,
                    mIDs: measurementIDs,
                    exportAsURL,
                    format,
                    fileType,
                    language: isBluetoothProduct ? exportLanguage : undefined,
                });
                analytics.logExportMeasurement(
                    page,
                    product,
                    format,
                    measurementIDs.length,
                    validIDs ? ExportAction.excludeAndExport : ExportAction.export,
                    isBluetoothProduct ? exportLanguage : undefined
                );
                logExportUserAction(format, measurementIDs.length, fileType);

                if (response.data instanceof Blob) {
                    saveAs(
                        response.data,
                        getFileNameFromContentDisposition(response.headers['content-disposition'] || '')
                    );
                } else if (exportAsURL) {
                    window.open(response.data.url, '_blank');
                }
            } finally {
                setIsLoading(false);
            }
        },
        [
            formatOption,
            product,
            mIDs,
            invalidIDs.length,
            isVerification,
            isLongRunningJobEnable,
            setExportConfig,
            isBluetoothProduct,
            exportLanguage,
            page,
        ]
    );

    const visible = showModal && product && !!mIDs?.length;

    useEffect(() => {
        if (!visible) {
            return;
        }
        const firstOption = exportConfig.type
            ? localisedExportOptions.find((option) => option.type === exportConfig.type)
            : product === ProductCode.SCHMIDT
              ? localisedExportOptions.find((option) => option.active !== false)
              : localisedExportOptions[0];
        if (firstOption) {
            setFormatOption(firstOption.type);
            setInvalidIDs(firstOption.invalidIDs);
        }
    }, [exportConfig.type, localisedExportOptions, product, visible]);

    const handleOnclose = useCallback(() => {
        setExportConfig({});
    }, [setExportConfig]);

    const handleExcludeAndExport = () => {
        if (!mIDs) return;
        const validIDs = mIDs.filter((id) => !invalidIDs.includes(id));
        handleExport(validIDs).finally(() => {
            if (exportConfig.setSelectedKeys) {
                exportConfig.setSelectedKeys(validIDs);
            }
            setFormatOption(undefined);
            handleOnclose();
            setShowInvalidModal(false);
            setInvalidIDs([]);
        });
    };

    const handleCloseInvalidModal = () => {
        setShowInvalidModal(false);
        setExportConfig((prevState) => {
            return { ...prevState, showModal: true };
        });
        if (formatOption && product && mIDs) {
            analytics.logExportMeasurement(
                page,
                product,
                formatConfig[formatOption].format,
                mIDs.length,
                ExportAction.cancel,
                isBluetoothProduct ? exportLanguage : undefined
            );
        }
    };

    return (
        <>
            <InvalidShareExportModal
                visible={showInvalidModal}
                onClose={handleCloseInvalidModal}
                invalidType={InvalidShareExportType.export}
                invalidMeasurementIDs={invalidIDs}
                onExclude={handleExcludeAndExport}
                selectedKeysCount={mIDs?.length ?? 0}
            />
            <StyledModal
                open={visible}
                title={<FormattedMessage id="Proceq.TableActionExport.Title" />}
                onCancel={handleOnclose}
                footer={
                    <AnalyticsButton
                        type="primary"
                        onClick={() => handleExport()}
                        loading={isLoading}
                        disabled={formatOption === undefined}
                    >
                        <span>
                            <FormattedMessage id="App.Export" />
                        </span>
                    </AnalyticsButton>
                }
                destroyOnClose
            >
                <div className={styles.export_options_wrapper}>
                    <div className={styles.export_item}>
                        <h3 className={styles.item_title}>
                            <FormattedMessage id="App.Format" />
                        </h3>
                        <Select<ExportFormat | PDFExtendedFileType>
                            className={styles.item_select}
                            value={formatOption}
                            onSelect={(value: ExportFormat | PDFExtendedFileType) => {
                                setFormatOption(value);
                                const exportOption = localisedExportOptions.find((item) => item.type === value);
                                if (exportOption) {
                                    setInvalidIDs(exportOption.invalidIDs);
                                }
                            }}
                        >
                            {localisedExportOptions.map((item) => (
                                <Select.Option key={item.type} value={item.type} disabled={item.active === false}>
                                    <FormattedMessage id={formatConfig[item.type].titleID} />
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    {isBluetoothProduct && (
                        <div className={styles.export_item}>
                            <h3 className={styles.item_title}>
                                <FormattedMessage id="App.Language" />
                            </h3>
                            <Select
                                className={styles.item_select}
                                value={exportLanguage}
                                onSelect={(value: string) => setExportLanguage(value)}
                            >
                                {exportReleasedLanguages.map((key) => (
                                    <Select.Option key={key} value={key}>
                                        <FormattedMessage id={languageTitleMap[key]} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    )}
                </div>
            </StyledModal>
        </>
    );
};

export default MeasurementExport;
