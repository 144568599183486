import React from 'react';
import { MeasurementFullData } from '../../../../../../types/measurement';
import { DerivedProps } from '../../../SharedTypes';
import styles from '../../../DataViewers.styl';
import FormattedMessage from '../../../../../../localization/FormatMessage';
import SectionTable from '../../../shared/SectionTable';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../../utils/tableDataUtils';
import { ReadingPanel } from '../../ReadingComponents';
import { getImperialDiameter, getSpotScanImperialMeanStdDev, getValMillimetersToMeters } from './statisticsUtils';
import { ScanType } from '../../../../../../types/proceq';

interface GetSectionDataParams {
    statistics: any;
    isMetric: boolean;
    isDiameter: boolean;
    scanType: ScanType;
}

// note that values here are saved in mm
const getSectionData = (params: GetSectionDataParams) => {
    const { statistics, scanType, isMetric, isDiameter } = params;
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.Profometer.Statistics.NumberOfReadings',
            value: statistics?.count,
        },
        {
            label: 'App.HTML.Profometer.Statistics.Min',
            value: getValMillimetersToMeters(statistics?.min),
            unitId: 'Profometer.Statistics.MinMax',
            enable: !isDiameter,
        },
        {
            label: 'App.HTML.Profometer.Statistics.Max',
            value: getValMillimetersToMeters(statistics?.max),
            unitId: 'Profometer.Statistics.MinMax',
            enable: !isDiameter,
        },
        {
            label: 'App.HTML.Profometer.Statistics.RebarDiameterMin',
            value: isMetric ? getValMillimetersToMeters(statistics?.min) : getImperialDiameter(statistics?.min),
            unitId: isMetric ? 'Profometer.Statistics.MinMax' : undefined,
            enable: isDiameter,
        },
        {
            label: 'App.HTML.Profometer.Statistics.RebarDiameterMax',
            value: isMetric ? getValMillimetersToMeters(statistics?.max) : getImperialDiameter(statistics?.max),
            unitId: isMetric ? 'Profometer.Statistics.MinMax' : undefined,
            enable: isDiameter,
        },
        {
            label: 'App.HTML.Profometer.Statistics.Mean',
            value: isMetric
                ? getValMillimetersToMeters(statistics?.mean)
                : getSpotScanImperialMeanStdDev(statistics?.mean, 'Profometer.Statistics.Mean'),
            skipConversion: !isMetric,
            unitId: 'Profometer.Statistics.Mean',
            enable: !isDiameter,
        },
        {
            label: 'App.HTML.Profometer.Statistics.StDev',
            value: isMetric
                ? getValMillimetersToMeters(statistics?.stdDev)
                : getSpotScanImperialMeanStdDev(statistics?.stdDev, 'Profometer.Statistics.StdDev'),
            skipConversion: !isMetric,
            unitId: 'Profometer.Statistics.StdDev',
            enable: !isDiameter,
        },
    ];
    return getConvertedSectionTableData(presetsRaw, scanType, isMetric);
};

const SpotScanStatistics: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, showTitle, isMetric, scanType } = props;
    const coverData = data.measurement?.content?.seriesContent?.statistics?.cover;
    const diameterData = data.measurement?.content?.seriesContent?.statistics?.diameter;

    return (
        <div>
            <ReadingPanel
                key="StatisticsOfCover"
                header={<FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfCover" />}
            >
                <SectionTable
                    showTitle={showTitle}
                    className={[styles.table, 'table-measurement-presets'].join(' ')}
                    title={
                        <span className={styles.sub_header}>
                            <FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfCover" />
                        </span>
                    }
                    dataSource={getSectionData({
                        statistics: coverData,
                        isMetric,
                        isDiameter: false,
                        scanType,
                    })}
                />
            </ReadingPanel>
            {(diameterData?.count ?? 0) > 0 && (
                <ReadingPanel
                    key="StatisticsOfDiameter"
                    header={<FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfDiameter" />}
                >
                    <SectionTable
                        showTitle={showTitle}
                        className={[styles.table, 'table-measurement-presets'].join(' ')}
                        title={
                            <span className={styles.sub_header}>
                                <FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfDiameter" />
                            </span>
                        }
                        dataSource={getSectionData({
                            statistics: diameterData,
                            isMetric,
                            isDiameter: true,
                            scanType,
                        })}
                    />
                </ReadingPanel>
            )}
        </div>
    );
};

export default SpotScanStatistics;
