import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { message, Progress, Spin } from 'antd';
import { routeUtils } from 'tds-common-fe';
import { copyTextToClipboard } from 'tds-common-fe/src/lib/utils/clipboardUtils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/browser';
import styles from '../../shared/AppLayout/AppLayout.styl';
import toolFrameStyles from './ToolFrame.styl';
import Header from '../../shared/Header';
import colors from '../../../styles/colors.json';
import { useAppsStatus } from '../../../hooks/useConfig';
import { productMap, ProductName } from '../../shared/AppIcon';
import { ProductCode } from '../../../types/proceq';
import {
    AppstreamAwsDetails,
    getAppstreamDetails,
    refreshWebsocketToken,
    setAppstreamSessionFinished,
} from '../../../api/appstreamService';
import ImportMessage from '../../MeasurementImport/ImportMessage';
import { ReactComponent as IconNotFound } from '../../../images/iconNotFound.svg';
import FormattedMessage from '../../../localization/FormatMessage';
import {
    generateResponse,
    MessageSendOp,
    sendMessage,
    SOCKET_SEND_ACTION,
    WebsocketCloseCodes,
    websocketsFactory,
} from './websocket';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { s3UploadFile } from './s3Utilities';
import { FormatIDs } from '../../../types';
import CopyLink from '../CopyLink';
import analytics from '../../../analytics/firebaseAnalytics';
import { InsightsAction, ShareLinkAction } from '../../../analytics/analyticsConstants';
import { getStandaloneToolPath } from '../../Routes/urls';
import config from '../../../config';
import { ReactComponent as LogoIcon } from '../../../images/appIconGPRInsightsWhite.svg';
import { SUPPORT_EMAIL } from '../../../constants';
import Button from '../../AnalyticsComponents/Button';
import { useCurrentUserEmail, useUserLicense } from '../../../hooks/useCurrentUser';
import { getLicenses } from '../../../api/userService';
import logger from '../../../logging/logger';
import StyledModal from '../../shared/StyledModal';
import { ProductFeature, useProductContext } from '../ProductContextProvider';

const updateUserInterfaceStateCallback = (event: any) => {
    logger.log(`appstream | session interface state change:${JSON.stringify(event)}`);
};

enum AppstreamSessionStatus {
    started = 'Started',
    ended = 'Ended',
    disconnected = 'Disconnected',
}

enum ToolErrors {
    noURL,
    invalidLicense,
    noService,
    sessionEnded,
    desktopSessionRunning,
}

// refresh token 30 min before actual expiration
const WEBSOCKET_REFRESH_TOKEN_BUFFER = 30 * 60 * 1000;
const LOADING_VIDEO_TIMEOUT = 60 * 1000;
const LOADING_TOASTER_KEY = 'loadingToasterKey';

const MAX_LOADING_PERCENT = 90;

const TOOL_ERROR_MAP: Record<ToolErrors, { text: FormatIDs; icon: React.FunctionComponent }> = {
    [ToolErrors.noURL]: { text: 'Tool.No.URL', icon: IconNotFound },
    [ToolErrors.invalidLicense]: { text: 'Tool.Invalid.License', icon: IconNotFound },
    [ToolErrors.noService]: { text: 'Tool.Service.TemporarilyUnavailable', icon: ExclamationCircleOutlined },
    [ToolErrors.desktopSessionRunning]: {
        text: 'Tool.Service.DesktopSessionRunning',
        icon: ExclamationCircleOutlined,
    },
    [ToolErrors.sessionEnded]: { text: 'Tool.Service.SessionEnded', icon: ExclamationCircleOutlined },
};

enum LoadingMediaType {
    video = 1,
    image,
}

const timeoutCleanup = (setTimeoutState: React.Dispatch<React.SetStateAction<NodeJS.Timeout | undefined>>) => {
    setTimeoutState((prevState) => {
        if (prevState) {
            clearInterval(prevState);
        }
        return undefined;
    });
};

let socket: any = {};

const ToolInsights: React.FunctionComponent = () => {
    const { appsStatus } = useAppsStatus();
    const navigate = useNavigate();
    const formatMessage = useFormatMessage();
    const params = useParams<{ productName: string }>();
    const { srcURL: locationSrcURL } = routeUtils.parseQuery<{ srcURL: string }>(location.search);
    const productInfo =
        productMap[params.productName ?? ProductName.GPRInsights] ?? productMap[ProductName.GPRInsights];
    const productCode = productInfo?.productCode ?? (params.productName as ProductCode);
    const productAppStatus = appsStatus[productCode];
    const ref = useRef<any>({ appstreamEmbed: null });
    const inputRef = useRef<HTMLInputElement>(null);
    const [srcUrl, setSrcURL] = useState<string | undefined>(locationSrcURL);
    const [userData, setUserData] = useState<AppstreamAwsDetails>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [reconnectSocket, setReconnectSocket] = useState(false);
    const [toolError, setToolError] = useState<ToolErrors | undefined>();
    const [showShareSessionModal, setShowShareSessionModal] = useState(false);
    const [shareSessionURL, setShareSessionURL] = useState<string | undefined>();
    const [sessionStarted, setSessionStarted] = useState(false);
    const [sessionEnded, setSessionEnded] = useState(false);
    const [showLoadingMedia, setShowLoadingMedia] = useState<LoadingMediaType | undefined>(LoadingMediaType.video);
    const [, setLoadingVideoTimeout] = useState<NodeJS.Timeout>();
    const [messageApi, loadingToasterContextHolder] = message.useMessage();

    const [messageLoadingProgress, setMessageLoadingProgress] = useState(0);
    const [, setMessageLoadingProgressInterval] = useState<NodeJS.Timeout>();

    const [, setWebsocketTimeout] = useState<NodeJS.Timeout>();
    const [websocketExpiration, setWebsocketExpiration] = useState(0);

    const email = useCurrentUserEmail();
    const { license } = useUserLicense();

    const wsHandshakeIntervalRef = useRef<undefined | NodeJS.Timeout>();

    const { isFeatureEnabled } = useProductContext();
    const isInsightsDesktopRunningEnabled = isFeatureEnabled(ProductFeature.INSIGHTS_DESKTOP_RUNNING);

    useEffect(() => {
        getLicenses(ProductCode.GPR_INSIGHTS);
    }, []);

    const logWebsocketAnalytics = useCallback(
        (event: string, appVersion: string, params?: { [key: string]: any }) => {
            analytics.logInsightsEvent(event, email ?? '', appVersion, params);
        },
        [email]
    );

    const updateSessionStateCallback = useCallback((event: any) => {
        if (event.sessionStatus === AppstreamSessionStatus.started) {
            // hide the loading video in 1 min regardless of websocket connection
            const loadingVideoTimeout = setTimeout(() => {
                setShowLoadingMedia(undefined);
                logger.log('removing loading media after 1 min');
            }, LOADING_VIDEO_TIMEOUT);
            setLoadingVideoTimeout(loadingVideoTimeout);
            setSessionStarted(true);
        }
        if (
            event.sessionStatus === AppstreamSessionStatus.ended ||
            event.sessionStatus === AppstreamSessionStatus.disconnected
        ) {
            setSessionStarted(false);
            setToolError(ToolErrors.sessionEnded);
            setSrcURL(undefined);
            if (socket && socket.close) {
                socket.close(WebsocketCloseCodes.CLOSE_NORMAL, WebsocketCloseCodes[WebsocketCloseCodes.CLOSE_NORMAL]);
            }
            setAppstreamSessionFinished();
        }
        logger.log(`appstream | session state change:${JSON.stringify(event)}`);
    }, []);

    useEffect(() => {
        if (srcUrl && !isLoading && showLoadingMedia) {
            const videoElement = document.querySelector('video');
            videoElement?.play().catch(() => {
                setShowLoadingMedia(LoadingMediaType.image);
            });
        }
    }, [isLoading, showLoadingMedia, srcUrl]);

    const resetLoadingVideo = () => {
        setShowLoadingMedia(undefined);
        setLoadingVideoTimeout((prevState) => {
            if (prevState) {
                clearTimeout(prevState);
            }
            return undefined;
        });
    };

    const appstreamErrorHandler = useCallback(
        (isStandaloneActive?: boolean) => {
            setIsLoading(false);
            if (isStandaloneActive && isInsightsDesktopRunningEnabled) {
                setToolError(ToolErrors.desktopSessionRunning);
            } else {
                setToolError(ToolErrors.noService);
            }
            setSrcURL(undefined);
        },
        [isInsightsDesktopRunningEnabled]
    );

    const sessionErrorCallback = useCallback((event: any) => {
        resetLoadingVideo();
        const eventError = JSON.stringify(event);
        logger.log(`appstream | session error:${eventError}`);
        Sentry.captureException(`appstream session error: ${eventError}`);
    }, []);

    // update loading progress 1% every 1s, capped at 90%
    useEffect(() => {
        const clearSetInterval = () => timeoutCleanup(setMessageLoadingProgressInterval);
        if (showLoadingMedia) {
            const interval = setInterval(() => {
                setMessageLoadingProgress((prevState) => {
                    if (prevState === MAX_LOADING_PERCENT) {
                        clearSetInterval();
                    }
                    return prevState === MAX_LOADING_PERCENT ? prevState : prevState + 1;
                });
            }, 1000);
            setMessageLoadingProgressInterval(interval);
        } else {
            setMessageLoadingProgress(0);
            clearSetInterval();
        }
    }, [showLoadingMedia]);

    useEffect(() => {
        if (srcUrl && !isLoading && showLoadingMedia) {
            messageApi.open({
                key: LOADING_TOASTER_KEY,
                type: 'loading',
                content: formatMessage({ id: 'Tool.Insights.Loading' }),
                duration: 0,
                icon: (
                    <Progress
                        type="circle"
                        percent={messageLoadingProgress}
                        showInfo={false}
                        size={22}
                        strokeWidth={12}
                        style={{ marginRight: 8 }}
                        strokeColor={colors.primary}
                    />
                ),
            });
        } else {
            messageApi.destroy(LOADING_TOASTER_KEY);
        }
    }, [formatMessage, isLoading, messageApi, messageLoadingProgress, showLoadingMedia, srcUrl]);

    useEffect(() => {
        if (locationSrcURL) return;
        if (Object.keys(appsStatus).length !== 0) {
            if (!productAppStatus || !productAppStatus.active) {
                logger.log('invalid license');
                setToolError(ToolErrors.invalidLicense);
                setIsLoading(false);
            } else if (productAppStatus && !productAppStatus.url) {
                logger.log('missing streaming url');
                setToolError(ToolErrors.noURL);
                setIsLoading(false);
            }
        }
    }, [appsStatus, locationSrcURL, productAppStatus]);

    useEffect(() => {
        if (websocketExpiration) {
            const milliseconds = websocketExpiration - Date.now() - WEBSOCKET_REFRESH_TOKEN_BUFFER;
            const reconnectWebsocketTimeout = setTimeout(() => {
                if (socket && socket.close) {
                    socket.close();
                }
            }, milliseconds);
            setWebsocketTimeout((prevState) => {
                if (prevState) {
                    clearTimeout(prevState);
                }
                return reconnectWebsocketTimeout;
            });
        }
    }, [websocketExpiration]);

    const loadScript = useCallback(
        (src: string) => {
            if (!document.getElementById('appstream-script')) {
                const tag = document.createElement('script');
                tag.async = false;
                tag.src = src;
                tag.id = 'appstream-script';
                tag.onload = () => {
                    if (locationSrcURL) {
                        setIsLoading(false);
                    }
                };
                const body = document.getElementsByTagName('body')[0];
                body.appendChild(tag);
            }
        },
        [locationSrcURL]
    );

    useEffect(() => {
        document.title = 'Screening Eagle GPR Insights';
        loadScript(`${process.env.PUBLIC_URL}/embed.js`);
        analytics.initializeInsightsInstance();

        return () => {
            const script = document.getElementById('appstream-script')!;
            document.body.removeChild(script);
            if (socket && socket.close) {
                socket.close(WebsocketCloseCodes.CLOSE_NORMAL, WebsocketCloseCodes[WebsocketCloseCodes.CLOSE_NORMAL]);
            }
            timeoutCleanup(setMessageLoadingProgressInterval);
            timeoutCleanup(setWebsocketTimeout);
        };
    }, [loadScript]);

    useEffect(() => {
        const licenseTier = license?.GPR_INSIGHTS?.tier?.name;
        if (licenseTier) {
            analytics.logInsightsLicenseTier(licenseTier);
        }
    }, [license?.GPR_INSIGHTS?.tier?.name]);

    const destroyFrame = () => {
        if (ref.current.appstreamEmbed) {
            ref.current.appstreamEmbed.destroy();
            ref.current.appstreamEmbed = null;
            delete ref.current.appstreamEmbed;
        }
    };

    const connectWebsocket = useCallback(
        (appstreamAwsDetails: AppstreamAwsDetails) => {
            setWebsocketExpiration(Date.parse(appstreamAwsDetails.credentials.Expiration));
            socket = websocketsFactory({
                ...appstreamAwsDetails,
                reconnectSocket: () => setReconnectSocket(true),
                resetHandshakeTimeout: () => {
                    // handshake from app is expected every 5 seconds. if not received within 6 seconds of last handshake, relaunch app
                    if (wsHandshakeIntervalRef.current) {
                        clearInterval(wsHandshakeIntervalRef.current);
                    }
                    wsHandshakeIntervalRef.current = setInterval(() => {
                        if (ref.current.appstreamEmbed && appstreamAwsDetails?.appId) {
                            logger.log(
                                `websocket | handshake not received within 6 seconds, relaunch app ${appstreamAwsDetails.appId}`
                            );
                            ref.current.appstreamEmbed.launchApp(appstreamAwsDetails.appId);
                        }
                    }, 6000);
                },
                showModal: () => setIsModalVisible(true),
                appstreamErrorHandler,
                hideLoadingVideo: resetLoadingVideo,
                shareProjectCallback: (text: string) =>
                    copyTextToClipboard(text, formatMessage({ id: 'Tool.Insights.CopyToClipboard.Error' })),
                logWebsocketAnalytics,
            });
        },
        [appstreamErrorHandler, formatMessage, logWebsocketAnalytics]
    );

    const [appstreamAwsDetails, setAppstreamAwsDetails] = useState<AppstreamAwsDetails | undefined>();
    const [firstLoad, setFirstLoad] = useState(true);
    useEffect(() => {
        if (!firstLoad || !productAppStatus?.url || locationSrcURL) return;
        getAppstreamDetails(appstreamErrorHandler).then((response) => setAppstreamAwsDetails(response));
        setFirstLoad(false);
    }, [appstreamErrorHandler, firstLoad, locationSrcURL, productAppStatus]);

    // separate setting of details from actual call above
    useEffect(() => {
        if (appstreamAwsDetails) {
            setSrcURL(appstreamAwsDetails.streamingURL);
            setIsLoading(false);
            setUserData(appstreamAwsDetails);
            connectWebsocket(appstreamAwsDetails);
        }
    }, [appstreamAwsDetails, connectWebsocket]);

    const getStreamingURL = useCallback(async () => {
        const response = await getAppstreamDetails(appstreamErrorHandler);
        const sessionURL = routeUtils.makeQueryPath(location.origin + getStandaloneToolPath(ProductName.GPRInsights), {
            srcURL: response?.streamingURL,
        });
        setShareSessionURL(sessionURL);
    }, [appstreamErrorHandler]);

    useEffect(() => {
        if (reconnectSocket) {
            logger.log('websocket | reconnecting');
            refreshWebsocketToken(appstreamErrorHandler).then((response) => {
                if (response && userData) {
                    connectWebsocket({ ...userData, ...response });
                }
            });
        }
        setReconnectSocket(false);
    }, [appstreamErrorHandler, connectWebsocket, reconnectSocket, userData]);

    const sendKeyboardEvent = (event: KeyboardEvent) => {
        if (ref.current.appstreamEmbed) {
            ref.current.appstreamEmbed.sendKeyboardEvent(event);
        }
    };

    const launchAppStreamSession = useCallback(
        (streamingURL: string) => {
            destroyFrame();
            if (streamingURL && streamingURL.length > 0) {
                logger.log(`appstream | starting new session`);
                const appstreamOptions = {
                    sessionURL: streamingURL,
                    userInterfaceConfig: {
                        [window.AppStream.Embed.Options.HIDDEN_ELEMENTS]: [window.AppStream.Embed.Elements.TOOLBAR],
                    },
                };
                ref.current.appstreamEmbed = new window.AppStream.Embed('appstream-container', appstreamOptions);
                ref.current.appstreamEmbed.addEventListener(
                    window.AppStream.Embed.Events.SESSION_STATE_CHANGE,
                    updateSessionStateCallback
                );
                ref.current.appstreamEmbed.addEventListener(
                    window.AppStream.Embed.Events.SESSION_INTERFACE_STATE_CHANGE,
                    updateUserInterfaceStateCallback
                );
                ref.current.appstreamEmbed.addEventListener(
                    window.AppStream.Embed.Events.SESSION_ERROR,
                    sessionErrorCallback
                );
                document.getElementById('keyboardInput')?.addEventListener('keypress', sendKeyboardEvent);
                document.getElementById('keyboardInput')?.addEventListener('keydown', sendKeyboardEvent);
                document.getElementById('keyboardInput')?.addEventListener('keyup', sendKeyboardEvent);
            }
        },
        [sessionErrorCallback, updateSessionStateCallback]
    );

    const appstreamContainer = useCallback(
        (node: any) => {
            if (node && srcUrl && !sessionEnded) {
                launchAppStreamSession(srcUrl);
            }
        },
        [launchAppStreamSession, sessionEnded, srcUrl]
    );

    const handleOk = () => {
        if (inputRef.current) inputRef.current.click();

        document.body.onfocus = () => {
            setIsModalVisible(false);
            setTimeout(() => {
                if (inputRef.current) {
                    const files = inputRef.current.files;
                    if (files?.length === 0) {
                        sendMessage(
                            socket,
                            generateResponse({
                                action: SOCKET_SEND_ACTION,
                                to: `${userData?.eagleId}_eaglepro`,
                                message: { op: 'upload_cancelled' },
                            })
                        );
                    }
                    inputRef.current.value = '';
                }
            }, 1000);

            document.body.onfocus = null;
        };
    };

    const handleCancel = () => {
        sendMessage(
            socket,
            generateResponse({
                action: SOCKET_SEND_ACTION,
                to: `${userData?.eagleId}_eaglepro`,
                message: { op: 'upload_cancelled' },
            })
        );
        setIsModalVisible(false);
    };

    const onUploadProgress = (progress: number) => {
        sendMessage(
            socket,
            generateResponse({
                action: SOCKET_SEND_ACTION,
                to: `${userData?.eagleId}_eaglepro`,
                message: { op: MessageSendOp.UPLOAD_PROGRESS, value: progress },
            })
        );
    };

    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files as FileList;
        if (files.length) {
            const res = [];
            const { s3, eagleId } = userData as AppstreamAwsDetails;
            for (const file of Array.from(files)) {
                try {
                    /* eslint-disable no-await-in-loop */
                    const uploadResult = await s3UploadFile({
                        s3,
                        file,
                        filePath: `${eagleId}/${file.name}`,
                        appstreamErrorHandler,
                        onUploadProgress,
                    });
                    res.push(`s3://${uploadResult.Bucket}/${uploadResult.Key}`);
                } catch (err) {
                    const uploadError = JSON.stringify(err);
                    logger.log(`s3 upload file error: ${uploadError}`);
                    Sentry.captureException(`s3 upload file error: ${uploadError}`);
                    sendMessage(
                        socket,
                        generateResponse({
                            action: SOCKET_SEND_ACTION,
                            to: `${eagleId}_eaglepro`,
                            message: { op: 'upload_failed', message: err },
                        })
                    );
                }
            }
            sendMessage(
                socket,
                generateResponse({
                    action: SOCKET_SEND_ACTION,
                    to: `${eagleId}_eaglepro`,
                    message: { op: 'upload_finished', files: res },
                })
            );
        }
    };

    const handleMailTo = useCallback(() => {
        window.open(`mailto:${SUPPORT_EMAIL}?subject=${formatMessage({ id: 'EmailSupport.Title' })}`, '_self');
        analytics.logInsightsAction(InsightsAction.support);
    }, [formatMessage]);

    return (
        <div className={toolFrameStyles.root}>
            {loadingToasterContextHolder}
            {srcUrl && (
                <>
                    <StyledModal
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText={formatMessage({ id: 'App.Insights.Modal.Confirm' })}
                        cancelText={formatMessage({ id: 'App.Insights.Modal.Cancel' })}
                        cancelButtonProps={{ type: 'primary', ghost: true }}
                        title={formatMessage({ id: 'App.Insights.Modal.Title' })}
                    >
                        <FormattedMessage id="App.Insights.Modal.Content" />
                    </StyledModal>
                    <input type="file" multiple ref={inputRef} onChange={handleInputChange} hidden />
                </>
            )}
            <Header
                showInsightsMenu
                hasLogo
                removeLinks
                background={colors.gray200}
                endSession={() => {
                    if (ref.current.appstreamEmbed) {
                        ref.current.appstreamEmbed.endSession();
                    }
                    setSessionEnded(true);
                }}
                isAppstreamReady={!(srcUrl === undefined || isLoading) && sessionStarted}
                shareSession={() => {
                    setShowShareSessionModal(true);
                    getStreamingURL();
                }}
                isStandaloneTool={!!locationSrcURL}
                relaunchSession={() => {
                    if (ref.current.appstreamEmbed && appstreamAwsDetails?.appId) {
                        logger.log(`appstream | launching app ${appstreamAwsDetails.appId}`);
                        ref.current.appstreamEmbed.launchApp(appstreamAwsDetails.appId);
                    }
                }}
            />
            <CopyLink
                modalTitle="Proceq.TitleShareSessionByUrl"
                visible={showShareSessionModal}
                onCancel={() => {
                    setShowShareSessionModal(false);
                    setShareSessionURL(undefined);
                    analytics.logShareLinkEvent(ShareLinkAction.done, ProductCode.GPR_INSIGHTS);
                }}
                modalBody={<FormattedMessage id="App.Insights.Modal.ShareBody" />}
                modalFooter={<FormattedMessage id="App.Insights.Modal.ShareFooter" />}
                sharingURL={shareSessionURL ?? ''}
                isLoading={shareSessionURL === undefined}
                product={ProductCode.GPR_INSIGHTS}
            />
            <div className={styles.body}>
                {isLoading && (
                    <div className={toolFrameStyles.container_body}>
                        <Spin />
                    </div>
                )}
                {srcUrl && !isLoading && !toolError && (
                    <>
                        <div
                            id="appstream-container"
                            ref={appstreamContainer}
                            className={toolFrameStyles.iframe_container}
                        />
                        {showLoadingMedia === LoadingMediaType.video && (
                            <video
                                muted
                                className={toolFrameStyles.loading_video}
                                autoPlay
                                src={`${config.EAGLE_CLOUD_DOMAIN}/insights-loading-video.mp4`}
                            />
                        )}
                        {showLoadingMedia === LoadingMediaType.image && (
                            <div className={toolFrameStyles.loading_image}>
                                <LogoIcon />
                            </div>
                        )}
                    </>
                )}
                {toolError && (
                    <div className={toolFrameStyles.container_body}>
                        <ImportMessage
                            Icon={TOOL_ERROR_MAP[toolError].icon}
                            message={
                                <div className={toolFrameStyles.tool_error_text}>
                                    <FormattedMessage
                                        id={TOOL_ERROR_MAP[toolError].text}
                                        values={{
                                            productName: productInfo.name,
                                            contactUs: (
                                                <Button
                                                    type="link"
                                                    onClick={handleMailTo}
                                                    className={toolFrameStyles.tool_error_contact}
                                                >
                                                    <FormattedMessage id="Tool.Service.TemporarilyUnavailable.ContactUs" />
                                                </Button>
                                            ),
                                        }}
                                    />
                                </div>
                            }
                            primaryAction={{
                                title: 'Import.GotoHomepage',
                                action: () => {
                                    navigate('/');
                                    document.title = formatMessage({ id: 'Title' });
                                },
                                type: 'default',
                                fillWidth: false,
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ToolInsights;
