import { difference, isEmpty, isEqual, union } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Pagination } from 'antd';
import { usePrevious } from 'tds-common-fe';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import styles from './DataView.styl';
import { useDataViewContext } from './DataViewProvider';
import { OrderDir } from '../../api/measurementService';
import { getMeasurementFolders } from '../../api/folderService';
import ButtonRefresh from './ButtonRefresh';
import ButtonExportBluetooth from '../MeasurementExport/ButtonExportBluetooth';
import ButtonExportWifi from '../MeasurementExport/ButtonExportWifi';
import MeasurementActionMenu, { LogbookButton } from './MeasurementActionMenu';
import { QueryKey } from './ColumnConfig/columnConfigMeasurement';
import { useImportMeasurementsInfo, useMeasurements, useProductData } from '../../hooks/useProductData';
import Readings from './DataViewers/Readings';
import {
    BluetoothFileType,
    CustomCurveFileType,
    DEFAULT_PAGE_SIZE,
    FilterConfig,
    MeasurementListItem,
    NON_FILE_MANAGEMENT_FOLDERS,
    SystemFolderID,
    ViewType,
} from '../../types/measurement';
import ButtonShare from './ButtonShare';
import { convertKeyValueToFilter } from './dataQueryUtils';
import { clearMeasurementTimestamps } from '../../actions/measurement';
import MeasurementExportProvider from '../MeasurementExport/MeasurementExportProvider';
import {
    ProductCode,
    productsWithLocalizedExports,
    productsWithVerificationData,
    supportedProductSet,
} from '../../types/proceq';
import { useContractRetriever } from '../../hooks/useContract';
import analytics from '../../analytics/firebaseAnalytics';
import { MeasurementMenuCategory, MeasurementPage } from '../../analytics/analyticsConstants';
import useImportMeasurementData from './Import/useImportMeasurementData';
import useImportMeasurementDidSuccess from './Import/useImportMeasurementDidSucess';
import ImportMeasurementContext from './Import/ImportMeasurementContext';
import { getDataViewRoute } from '../Routes/urls';
import { productCodeToIconMap } from '../shared/AppIcon';
import { ToggleHaze, ToggleUnsynced } from './ToggleSwitch';
import MeasurementTable, { getMeasurementColumns } from './MeasurementTable';
import MeasurementButtonDelete from './MeasurementButtonDelete';
import ButtonMove from './ButtonMove';
import ButtonArchiveRestore from './ButtonArchiveRestore';
import { ProductFeature, useProductContext } from './ProductContextProvider';
import ButtonUpload from './UploadCustomCurve/ButtonUpload';
import CurveChart from './UploadCustomCurve/ExpandedView/CurveChart';
import useCreateMeasurementDataSource from './CreateMeasurement/useCreateMeasurementDataSource';
import CurveReadings from './UploadCustomCurve/ExpandedView/CurveReadings';
import { CustomCurveProbeInfo } from '../../types/customCurve';
import UnsyncedButtons from './LaunchApp/UnsyncedButtons';
import getIsMeasurementUnsynced from './DataViewers/utils/getIsMeasurementUnsynced';
import { ReactQueryKeys } from '../../queries/queryKeys';
import ViewInNewTabButton from './ViewInNewTabButton';
import MeasurementModalProvider from './MeasurementModals/MeasurementModalProvider';
import { useMeasurementContext } from './MeasurementContext/MeasurementProvider';

const DataTableMeasurement: React.FunctionComponent = () => {
    const { product, activeFolder, setActiveFolder, viewType, withUnsynced } = useDataViewContext();
    const {
        fetchMeasurementList,
        isFetching,
        selectedKeys,
        setSelectedKeys,
        measurementListParams,
        setMeasurementListParams,
    } = useMeasurementContext();
    const { page, pageSize, orderConfig, searchText, filterConfig, productModel, probeTypeId, fileType } =
        measurementListParams;
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const productData = useProductData(product);
    const measurements = useMeasurements();
    const isArchiveViewType = viewType === ViewType.Archived;

    const { isFeatureEnabled } = useProductContext();
    const isArchiveEnabled = isFeatureEnabled(ProductFeature.ARCHIVE_MEASUREMENTS);
    const allowShareUnsyncedGMMeasurements = isFeatureEnabled(ProductFeature.SHARE_UNSYNCED_GM_MEASUREMENTS);

    const [showShareButton, setShowShareButton] = useState(true);

    const {
        measurementIDs: activeMeasurementIDs = {},
        verificationIDs,
        dgsccIDs,
        measurementCount: activeMeasurementCount,
        importIDs,
        createMeasurementIDs,
        archivedData = {},
    } = productData;
    const { measurementIDs: archivedMeasurementIDs = {}, measurementCount: archivedMeasurementCount } = archivedData;
    const prevCreateMeasurementIDs = usePrevious(createMeasurementIDs?.length ?? 0) || 0;
    const measurementIDs = isArchiveViewType ? archivedMeasurementIDs : activeMeasurementIDs;
    const measurementCount = isArchiveViewType ? archivedMeasurementCount : activeMeasurementCount;

    const { product: importingProduct } = useImportMeasurementsInfo();
    const isSameProductWithImportingProduct = importingProduct === product;

    const { setViewFilesHandler } = useContext(ImportMeasurementContext);
    const navigate = useNavigate();

    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const handleSelectKeys = useCallback(
        (values: React.Key[]) => {
            setSelectedKeys(values.map((d) => d.toString()));
            if (!allowShareUnsyncedGMMeasurements && product === ProductCode.GPR_MOUNTED) {
                // if there are unsynced measurements, hide the share button
                setShowShareButton(
                    !values.some((key) => {
                        return getIsMeasurementUnsynced(measurements[key.toString()].measurement as any);
                    })
                );
            }
        },
        [allowShareUnsyncedGMMeasurements, measurements, product, setSelectedKeys]
    );

    // Reset selections and expansion
    useEffect(() => {
        setSelectedKeys([]);
        setExpandedRowKeys([]);
        setShowShareButton(true);
    }, [product, activeFolder, fileType, setSelectedKeys, viewType]);

    const { state } = useLocation();
    const initMeasurementID = state?.measurementID;

    // Set initial expand
    useEffect(() => {
        if (product && initMeasurementID) {
            setExpandedRowKeys([initMeasurementID]);
        }
    }, [product, initMeasurementID]);

    // Create a custom action for view files action in import success message
    // If already in the correct route, need to change the active folder
    useEffect(() => {
        if (isSameProductWithImportingProduct && product) {
            setViewFilesHandler({
                action: () => {
                    const route = getDataViewRoute({ productName: productCodeToIconMap[product] || product, viewType });
                    navigate(route);
                    setActiveFolder(SystemFolderID.Imported);
                },
            });
            return () => {
                setViewFilesHandler({});
            };
        }
        return () => {};
    }, [isSameProductWithImportingProduct, setViewFilesHandler, product, setActiveFolder, viewType, navigate]);

    const isCustomCurve = fileType === CustomCurveFileType.dgscc;
    const isVerificationFile = fileType === BluetoothFileType.VerificationData;

    const dataSourceIDs = useMemo(
        () => (isCustomCurve ? dgsccIDs : isVerificationFile ? verificationIDs : measurementIDs[activeFolder]),
        [isCustomCurve, dgsccIDs, isVerificationFile, verificationIDs, measurementIDs, activeFolder]
    );

    const { dataSource: measurementDataSource, filteredDataSourceIDs: measurementDataSourceIDs } = useMemo(() => {
        const filteredDataSourceIDs: string[] = [];
        const dataSource = (dataSourceIDs ?? [])
            .filter((id) => measurements[id]?.measurement)
            .map((id) => {
                const measurement = measurements[id].measurement;
                const settings = measurements[id]?.settings;

                filteredDataSourceIDs.push(measurement.id);

                return {
                    key: measurement.id,
                    ...measurement,
                    settings,
                };
            });
        return { dataSource, filteredDataSourceIDs };
    }, [measurements, dataSourceIDs]);

    const importMeasurementsDataSource = useImportMeasurementData({
        activeFolder,
        page,
        importIDs,
        fileType,
        product,
    });

    const createMeasurementsDataSource = useCreateMeasurementDataSource({
        page,
        createMeasurementIDs,
        fileType,
        product,
    });

    const combinedDataSource: typeof measurementDataSource = useMemo(() => {
        return isArchiveViewType
            ? measurementDataSource
            : isCustomCurve
              ? [...createMeasurementsDataSource, ...importMeasurementsDataSource, ...measurementDataSource]
              : [...importMeasurementsDataSource, ...measurementDataSource];
    }, [
        createMeasurementsDataSource,
        importMeasurementsDataSource,
        isArchiveViewType,
        isCustomCurve,
        measurementDataSource,
    ]);

    const totalMeasurementCount = useMemo(() => {
        if (!measurementCount) return 0;
        if (isVerificationFile || isCustomCurve) {
            return measurementCount[fileType] ?? 0;
        }
        return measurementCount[activeFolder] ?? 0;
    }, [measurementCount, isVerificationFile, isCustomCurve, activeFolder, fileType]);

    const allowExpandMeasurement = product !== ProductCode.PIT_IE && !isArchiveViewType;

    // open ellipses dropdown menu if id matches
    const [openRowDropdownID, setOpenRowDropdownID] = useState<string | undefined>();

    const renderActionCell = useCallback(
        (value: string, measurement: MeasurementListItem) => {
            if (measurement.isImport || measurement.isCreate) {
                return null;
            }
            if (product === ProductCode.GPR_MOUNTED) {
                if (getIsMeasurementUnsynced(measurement)) {
                    return (
                        <div className={styles.action_wrapper}>
                            <UnsyncedButtons
                                measurement={measurement}
                                product={product}
                                fileType={fileType}
                                setSelectedKeys={setSelectedKeys}
                            />
                        </div>
                    );
                }
            }
            if (isArchiveViewType && product) {
                return (
                    <div className={styles.action_wrapper}>
                        <ButtonArchiveRestore
                            product={product}
                            mIDs={[value]}
                            setSelectedKeys={setSelectedKeys}
                            isTableButton
                            isArchiveViewType={isArchiveViewType}
                        />
                        <MeasurementButtonDelete
                            product={product}
                            fileType={fileType}
                            mIDs={[value]}
                            isPermanentDelete
                            primaryStyle={false}
                            isTableButton
                        />
                    </div>
                );
            }

            return (
                <div className={styles.action_wrapper}>
                    {!isCustomCurve && (
                        <>
                            <LogbookButton measurementID={value} onClick={() => analytics.logViewLogbook(product)} />
                            <ViewInNewTabButton measurement={measurement} />
                        </>
                    )}
                    <MeasurementActionMenu
                        measurement={measurement}
                        fileType={fileType}
                        openRowDropdownID={openRowDropdownID}
                        setOpenRowDropdownID={setOpenRowDropdownID}
                        setSelectedKeys={setSelectedKeys}
                    />
                </div>
            );
        },
        [fileType, isArchiveViewType, isCustomCurve, openRowDropdownID, product, setSelectedKeys]
    );

    const onExpandRow = useCallback(
        (id: string, isExpanded: boolean) => {
            analytics.logMeasurementView(MeasurementPage.measurement, product);
            setExpandedRowKeys((keys) => {
                return isExpanded ? difference(keys, [id]) : union(keys, [id]);
            });
        },
        [product]
    );

    const { columns, columnsWidth } = useMemo(() => {
        return getMeasurementColumns({
            product,
            fileType,
            renderActionCell,
            isHTMLView: false,
            preventFlagClick: activeFolder === SystemFolderID.Trashed || isArchiveViewType,
            allowExpansion: product !== ProductCode.PIT_IE && !isArchiveViewType,
            isExpanded: (id: string) => expandedRowKeys.includes(id),
            onExpand: onExpandRow,
        });
    }, [product, fileType, renderActionCell, activeFolder, isArchiveViewType, onExpandRow, expandedRowKeys]);

    const expandedRowRender = useCallback(
        (record: MeasurementListItem, index: number, indent: number, expanded: boolean) => {
            return expanded ? (
                isCustomCurve ? (
                    <>
                        <CurveChart
                            product={product}
                            measurementID={record.id}
                            probeInfo={record.probeinfo as any as CustomCurveProbeInfo}
                        />
                        <CurveReadings probeInfo={record.probeinfo as any as CustomCurveProbeInfo} />
                    </>
                ) : (
                    <Readings product={product} measurementID={record.id} />
                )
            ) : null;
        },
        [isCustomCurve, product]
    );

    const showLoading = useMemo(() => {
        return (
            isFetching &&
            (measurementDataSource.slice(pageSize * (page - 1), pageSize * page).some((data) => !data.id) ||
                isEmpty(measurementDataSource))
        );
    }, [isFetching, measurementDataSource, pageSize, page]);

    const handleClearMeasurementTimestamps = useCallback(() => {
        dispatch(clearMeasurementTimestamps());
    }, [dispatch]);

    const refreshData = useCallback(
        async (skipVerificationData: boolean) => {
            if (product) {
                handleClearMeasurementTimestamps();
                const getMeasurements = fetchMeasurementList();
                const getFolders = getMeasurementFolders({ product, withUnsynced, archived: isArchiveViewType });

                const promises: Promise<any>[] = [getFolders, getMeasurements];

                if (productsWithVerificationData.has(product) && !skipVerificationData) {
                    // only get verification data count again if user is NOT already viewing verification data
                    if (!isVerificationFile) {
                        const getVerificationData = queryClient.invalidateQueries({
                            queryKey: ReactQueryKeys.verificationDataCount(product, withUnsynced),
                        });
                        promises.push(getVerificationData);
                    }
                    const getCustomMaterials = queryClient.invalidateQueries({
                        queryKey: ReactQueryKeys.customMaterialCount(product, withUnsynced),
                    });
                    promises.push(getCustomMaterials);
                }
                await Promise.all(promises);
            }
        },
        [
            product,
            handleClearMeasurementTimestamps,
            fetchMeasurementList,
            withUnsynced,
            isArchiveViewType,
            isVerificationFile,
            queryClient,
        ]
    );

    const handleRefresh = async () => {
        await refreshData(false);
        if (product) {
            analytics.logRefreshMeasurements(product);
        }
    };

    const handleOnChange = useCallback(
        (
            _: any,
            tableFilters: Record<string, (React.Key | boolean)[] | null>,
            tableSorter: SorterResult<MeasurementListItem> | SorterResult<MeasurementListItem>[]
        ) => {
            const parsedFilters: { [key: string]: FilterConfig } = {};
            let shouldResetConfig = false;
            if (tableFilters) {
                for (const key in tableFilters) {
                    const values = tableFilters[key];
                    switch (key) {
                        case QueryKey.Name: {
                            if (values) {
                                const newSearchText = values?.[0]?.toString() ?? '';
                                if (searchText !== newSearchText) {
                                    setMeasurementListParams((prevState) => ({
                                        ...prevState,
                                        searchText: newSearchText,
                                    }));
                                    shouldResetConfig = true;
                                }
                            } else {
                                setMeasurementListParams((prevState) => ({ ...prevState, searchText: '' }));
                            }
                            break;
                        }
                        case QueryKey.Type: {
                            if (values) {
                                const newFileType = values?.[0]?.toString() ?? '';
                                if (newFileType !== fileType) {
                                    setMeasurementListParams((prevState) => ({ ...prevState, fileType: newFileType }));
                                    shouldResetConfig = true;
                                }
                            } else {
                                setMeasurementListParams((prevState) => ({ ...prevState, fileType: '' }));
                            }
                            break;
                        }
                        case QueryKey.Probe: {
                            if (values) {
                                const newProductModel = values?.[0]?.toString() ?? '';
                                if (newProductModel !== productModel) {
                                    setMeasurementListParams((prevState) => ({
                                        ...prevState,
                                        productModel: newProductModel,
                                    }));
                                    shouldResetConfig = true;
                                }
                            } else {
                                setMeasurementListParams((prevState) => ({ ...prevState, productModel: undefined }));
                            }
                            break;
                        }
                        case QueryKey.BluetoothProbe: {
                            if (values) {
                                const newProbeTypeId = Number(values?.[0]) ?? undefined;
                                if (newProbeTypeId !== probeTypeId) {
                                    setMeasurementListParams((prevState) => ({
                                        ...prevState,
                                        probeTypeId: newProbeTypeId,
                                    }));
                                    shouldResetConfig = true;
                                }
                            } else {
                                setMeasurementListParams((prevState) => ({ ...prevState, probeTypeId: undefined }));
                            }
                            break;
                        }

                        default: {
                            if (values) {
                                const filter = convertKeyValueToFilter(key, values);
                                parsedFilters[key] = filter;
                            }
                        }
                    }
                }
                if (!isEqual(parsedFilters, filterConfig)) {
                    setMeasurementListParams((prevState) => ({ ...prevState, filterConfig: parsedFilters }));
                    shouldResetConfig = true;
                }
            }

            if (!Array.isArray(tableSorter) && tableSorter.column) {
                const orderBy = String(tableSorter?.columnKey);
                const orderDir = tableSorter?.order === 'ascend' ? OrderDir.ASC : OrderDir.DESC;
                const newOrderConfig = { orderBy, orderDir };
                if (!isEqual(orderConfig, newOrderConfig)) {
                    analytics.logSortTable(newOrderConfig.orderBy);
                    setMeasurementListParams((prevState) => ({ ...prevState, orderConfig: newOrderConfig }));
                }
            } else {
                setMeasurementListParams((prevState) => ({ ...prevState, orderConfig: undefined }));
            }

            if (shouldResetConfig) {
                setSelectedKeys([]);
                setMeasurementListParams((prevState) => ({ ...prevState, page: 1 }));
            }
        },
        [
            filterConfig,
            searchText,
            setMeasurementListParams,
            fileType,
            productModel,
            probeTypeId,
            orderConfig,
            setSelectedKeys,
        ]
    );

    // Refresh the data when a file successfully imported.
    // Increase the folder count and also show the new data if any.
    // Only refresh in All and Imported folder as imported data will be in Imported folder.
    // Skip if the table displaying product is not the same the importing product.
    const shouldRefreshUponImport =
        isSameProductWithImportingProduct &&
        (activeFolder === SystemFolderID.All || activeFolder === SystemFolderID.Imported) &&
        fileType !== BluetoothFileType.VerificationData;

    const handleImportSuccess = useCallback(() => {
        if (shouldRefreshUponImport) {
            refreshData(true);
        }
    }, [refreshData, shouldRefreshUponImport]);

    useImportMeasurementDidSuccess(handleImportSuccess);

    // refresh data when there is a change in created measurements
    useEffect(() => {
        if (prevCreateMeasurementIDs > (createMeasurementIDs?.length ?? 0) && isCustomCurve) {
            refreshData(true);
        }
    }, [createMeasurementIDs, fileType, isCustomCurve, prevCreateMeasurementIDs, refreshData]);

    const hasLocalizedExports = product && productsWithLocalizedExports.has(product);
    const isProductSupported = product && supportedProductSet.has(product);
    const isNonFileManagementFolder = NON_FILE_MANAGEMENT_FOLDERS.has(activeFolder as SystemFolderID);

    // Fetch the contracts
    useContractRetriever(measurementDataSourceIDs);

    return (
        <div className={styles.content_column} data-mode={isArchiveViewType ? 'archive' : 'active'}>
            <div className={styles.dataView_header}>
                <div className={styles.common_actions}>
                    {selectedKeys.length === 0 && <ButtonRefresh onClick={handleRefresh} disabled={isFetching} />}
                    {isCustomCurve && selectedKeys.length === 0 && <ButtonUpload />}
                    {product && isProductSupported && selectedKeys.length > 0 && (
                        <>
                            <MeasurementExportProvider page={MeasurementPage.measurement}>
                                {isNonFileManagementFolder ||
                                isCustomCurve ||
                                isArchiveViewType ? null : hasLocalizedExports ? (
                                    <ButtonExportBluetooth
                                        product={product}
                                        mIDs={selectedKeys}
                                        fileType={fileType}
                                        analyticsPage={MeasurementPage.measurement}
                                        setSelectedKeys={setSelectedKeys}
                                    />
                                ) : (
                                    <ButtonExportWifi
                                        product={product}
                                        mIDs={selectedKeys}
                                        analyticsPage={MeasurementPage.measurement}
                                        setSelectedKeys={setSelectedKeys}
                                        fileType={fileType}
                                    />
                                )}
                                {!(isVerificationFile || isNonFileManagementFolder || isArchiveViewType) && (
                                    <>
                                        {showShareButton && (
                                            <ButtonShare
                                                product={product}
                                                productModel={productModel}
                                                mIDs={selectedKeys}
                                                setSelectedKeys={setSelectedKeys}
                                                primaryStyle={isCustomCurve}
                                                isCustomCurve={isCustomCurve}
                                            />
                                        )}
                                        {!isCustomCurve && (
                                            <ButtonMove mIDs={selectedKeys} setSelectedKeys={setSelectedKeys} />
                                        )}
                                    </>
                                )}
                                {isArchiveEnabled &&
                                    !isNonFileManagementFolder &&
                                    !isCustomCurve &&
                                    !isVerificationFile && (
                                        <ButtonArchiveRestore
                                            product={product}
                                            mIDs={selectedKeys}
                                            setSelectedKeys={setSelectedKeys}
                                            isArchiveViewType={isArchiveViewType}
                                        />
                                    )}
                                <MeasurementButtonDelete
                                    product={product}
                                    fileType={fileType}
                                    mIDs={selectedKeys}
                                    isPermanentDelete={
                                        activeFolder === SystemFolderID.Trashed ||
                                        isVerificationFile ||
                                        isCustomCurve ||
                                        isArchiveViewType
                                    }
                                    primaryStyle={!isVerificationFile && !isCustomCurve && !isArchiveViewType}
                                />
                            </MeasurementExportProvider>
                        </>
                    )}
                    {product === ProductCode.GLM && <ToggleHaze />}
                    {product && isProductSupported && <ToggleUnsynced />}
                </div>
                <Pagination
                    size="small"
                    pageSize={pageSize}
                    current={page}
                    total={totalMeasurementCount}
                    pageSizeOptions={['20', '50', '100']}
                    onChange={(page, pageSize) => {
                        setMeasurementListParams((prevState) => ({
                            ...prevState,
                            page,
                            pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
                        }));
                    }}
                    className={styles.dataView_pagination}
                    showSizeChanger
                />
            </div>
            <div className={styles.dataView_content}>
                <MeasurementModalProvider>
                    <MeasurementTable
                        columns={columns}
                        columnsWidth={columnsWidth}
                        tableKey={`${product}-${isVerificationFile}-${isCustomCurve}-${isArchiveViewType}`}
                        dataSource={combinedDataSource}
                        rowSelection={{ selectedRowKeys: selectedKeys, onChange: handleSelectKeys }}
                        expandable={
                            allowExpandMeasurement
                                ? {
                                      rowExpandable: (record) => !getIsMeasurementUnsynced(record),
                                      expandedRowRender,
                                      expandedRowKeys,
                                      expandIconColumnIndex: -1,
                                  }
                                : {}
                        }
                        onRow={(record: { id: string }) => {
                            if (isArchiveViewType) return undefined;
                            return {
                                onClick: () => {
                                    onExpandRow(record.id, expandedRowKeys.includes(record.id));
                                },
                                onContextMenu: (event: any) => {
                                    // prevent default browser context menu from opening
                                    event.preventDefault();
                                    setOpenRowDropdownID(record.id);
                                    analytics.logMeasurementMenu(MeasurementMenuCategory.more);
                                }, // right button click row
                            };
                        }}
                        onChange={handleOnChange}
                        loading={showLoading}
                        pagination={false}
                        isArchiveViewType={isArchiveViewType}
                    />
                </MeasurementModalProvider>
            </div>
        </div>
    );
};
export default DataTableMeasurement;
