import { get } from 'lodash';
import { roundToNDigit } from '../../../utils/conversionRates';
import { getASTMImperialDiameterRebarSize } from '../../../../../../types/measurement';
import ProfometerStatisticsDoc from '../../../../UnitDocumentions/ProfometerStatistics.json';

interface GetStatisticsValueParams {
    roundToDigit: number;
    value?: number;
}

export const getStatisticsValue = ({ roundToDigit, value }: GetStatisticsValueParams) =>
    value === undefined || isNaN(value) ? '-' : roundToNDigit(value, roundToDigit);

export const getImperialDiameter = (value?: number) => {
    if (value === undefined || isNaN(value)) return '-';
    return getASTMImperialDiameterRebarSize(value.toString());
};

export const getValMillimetersToMeters = (value?: number) => {
    if (value === undefined || isNaN(value)) return '-';
    return value / 1000;
};

// special handling required for spot scan's statistics' mean and std dev
export const getSpotScanImperialMeanStdDev = (value: number, unitId: string) => {
    if (value === undefined || isNaN(value)) return '-';
    // convert from mm to in
    const valueAfterConversion = +value * 0.0393701;
    // app's conversion logic
    return `${roundToNDigit(Math.round(+valueAfterConversion / 0.005) * 0.005, get(ProfometerStatisticsDoc, [unitId, 'imperial', 'digits'], 3))}"`;
};
