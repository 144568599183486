import React, { useEffect, useState } from 'react';
import { App, Col, Form, Radio, Row, Select } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useQueryClient } from '@tanstack/react-query';
import { pick } from 'lodash';
import { FormInstance } from 'antd/lib/form';
import { getFileName } from 'tds-common-fe/src/lib/utils/fileUtils';
import StyledModal from '../../shared/StyledModal';
import FormattedMessage from '../../../localization/FormatMessage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import UploadDragger from '../../shared/Upload/UploadDragger';
import styles from './SpatialDataUploadModal.styl';
import UploadedFileList from '../UploadCustomCurve/UploadedFileList';
import {
    SpatialDataOption,
    REQUIRED_FIELDS_BY_PROJECT_TYPE,
    SPATIAL_DATA_CATEGORY_ID,
    SpatialDataFormFieldNames,
    SpatialDataProjectionType,
} from '../../../types/userFiles';
import { checkUserFileNameExists, createUserFile } from '../../../api/userFileService';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { useUserFilesContext } from './UserFilesProvider';
import { uploadFile } from '../../../api/uploadFileService';
import { useDataViewContext } from '../DataViewProvider';
import { getFileExtension, InvalidSpatialZipFile, validateSpatialZipFile } from '../../../utils/fileUtils';
import { ReactQueryKeys } from '../../../queries/queryKeys';
import { useUserFileOption } from '../../../queries/userFileQueries';
import analytics from '../../../analytics/firebaseAnalytics';
import { UploadCadgisFileAction } from '../../../analytics/analyticsConstants';
import { FormatIDs } from '../../../types';
import { ProductFeature, useProductContext } from '../ProductContextProvider';

interface SpatialDataUploadModalProps {
    visible: boolean;
    onClose: () => void;
}

const PROJECTION_OPTIONS = [
    {
        label: <FormattedMessage id="DataView.Upload.SpatialData.Upload.ProjectionType.UTM" />,
        value: SpatialDataProjectionType.utm,
    },
    {
        label: <FormattedMessage id="DataView.Upload.SpatialData.Upload.ProjectionType.Country" />,
        value: SpatialDataProjectionType.country,
    },
];

const getProjectTypeOptions = (isRelativeEnabled: boolean) => {
    return isRelativeEnabled
        ? [
              ...PROJECTION_OPTIONS,
              {
                  label: <FormattedMessage id="DataView.Upload.SpatialData.Upload.ProjectionType.Relative" />,
                  value: SpatialDataProjectionType.relative,
              },
          ]
        : PROJECTION_OPTIONS;
};

const ZIP_FILE_ERRORS: Record<InvalidSpatialZipFile, FormatIDs> = {
    [InvalidSpatialZipFile.noShapeFile]: 'DataView.Upload.SpatialData.Upload.Error.NoShapeFile',
    [InvalidSpatialZipFile.multipleBundles]: 'DataView.Upload.SpatialData.Upload.Error.MultipleBundles',
};

const getAttributes = (form: FormInstance, projectionType: SpatialDataProjectionType) => {
    const attributes = pick(form.getFieldsValue(), REQUIRED_FIELDS_BY_PROJECT_TYPE[projectionType]);

    // UTM zone's epsg is hidden in ui and saved under different field name. Replace it to the correct key when sending to backend
    if (projectionType === SpatialDataProjectionType.utm) {
        attributes.epsg = attributes.utmZoneEPSG;
        delete attributes.utmZoneEPSG;
    }

    // for 'relative' there is no epsg set, default to 0.
    if (projectionType === SpatialDataProjectionType.relative) {
        attributes.epsg = 0;
    }
    return attributes;
};

const SpatialDataUploadModal: React.FunctionComponent<SpatialDataUploadModalProps> = (props) => {
    const { onClose, visible } = props;
    const [form] = Form.useForm();
    const [dataFile, setDataFile] = useState<RcFile | undefined>();
    const [isUploading, setIsUploading] = useState(false);
    const formatMessage = useFormatMessage();
    const { product } = useDataViewContext();
    const queryClient = useQueryClient();
    const { message } = App.useApp();

    const [projectionType, setProjectionType] = useState<SpatialDataProjectionType>(SpatialDataProjectionType.utm);
    const { fileExtensions } = useUserFilesContext();
    const spatialDataFileExtensions = (fileExtensions[SPATIAL_DATA_CATEGORY_ID]?.extensions ?? []).map((ext) =>
        ext.toLowerCase()
    );

    const { data } = useUserFileOption(product);

    const [coordinateSystemOptions, setCoordinateSystemOptions] = useState<SpatialDataOption[]>([]);

    const { isFeatureEnabled } = useProductContext();
    const isRelativeOptionEnabled = isFeatureEnabled(ProductFeature.SPATIAL_DATA_RELATIVE_OPTION);
    const projectionTypeOptions = getProjectTypeOptions(isRelativeOptionEnabled);

    // initialise the coordinate system options
    useEffect(() => {
        if (data?.countryCoordinateSystems && data?.countries) {
            setCoordinateSystemOptions(data.countryCoordinateSystems[data.countries[0].value]);
        }
    }, [data, form]);

    const handleClose = () => {
        onClose();
        setDataFile(undefined);
        setProjectionType(SpatialDataProjectionType.utm);
        form.resetFields();
        analytics.logUploadCadgisFile(UploadCadgisFileAction.cancel);
    };

    return (
        <StyledModal
            width={856}
            forceRender
            onCancel={handleClose}
            open={visible}
            title={<FormattedMessage id="DataView.Upload.SpatialData.Upload.Title" />}
            footer={
                <AnalyticsButton
                    loading={isUploading}
                    disabled={!dataFile}
                    type="primary"
                    onClick={async () => {
                        analytics.logUploadCadgisFile(UploadCadgisFileAction.upload);
                        setIsUploading(true);
                        try {
                            if (dataFile) {
                                const { fileID } = await uploadFile({
                                    file: dataFile,
                                    product: product ?? '',
                                });
                                await createUserFile({
                                    fID: fileID,
                                    name: getFileName(dataFile.name),
                                    extension: getFileExtension(dataFile.name),
                                    attributes: getAttributes(form, projectionType),
                                });
                                queryClient.invalidateQueries({ queryKey: [...ReactQueryKeys.userFile, 'list'] });
                                handleClose();
                            }
                        } finally {
                            setIsUploading(false);
                        }
                    }}
                >
                    <FormattedMessage id="App.Upload" />
                </AnalyticsButton>
            }
            styles={{ body: { overflow: 'auto', gap: 24 } }}
        >
            <UploadDragger
                extraSubtitle={
                    <>
                        <p className={styles.caption}>
                            <FormattedMessage id="DataView.Upload.SpatialData.Upload.Subtitle" />
                        </p>
                        <br />
                        <p className={styles.guideline_text}>
                            <FormattedMessage id="DataView.Upload.SpatialData.Upload.Subtitle.OneFile" />
                        </p>
                    </>
                }
                onDrop={async (file, fileExt) => {
                    if (fileExt.toLowerCase() === 'zip') {
                        const zipFileError = await validateSpatialZipFile(file);
                        if (zipFileError) {
                            message.error(formatMessage({ id: ZIP_FILE_ERRORS[zipFileError] }), 10);
                            return;
                        }
                    }
                    const fileExists = await checkUserFileNameExists({ name: file.name });
                    if (fileExists) {
                        message.error(
                            formatMessage({ id: 'DataView.Upload.Error.NameExists' }, { fileName: <b>{file.name}</b> }),
                            10
                        );
                    } else {
                        setDataFile(file);
                    }
                }}
                supportedFormats={spatialDataFileExtensions}
            />
            <UploadedFileList
                file={dataFile}
                onDeleteFile={() => {
                    analytics.logUploadCadgisFile(UploadCadgisFileAction.deleteFile);
                    setDataFile(undefined);
                }}
            />
            {data && data.utmZones.length && data.countries.length && (
                <Form
                    form={form}
                    requiredMark={false}
                    colon={false}
                    layout="vertical"
                    initialValues={{
                        [SpatialDataFormFieldNames.projectionType]: SpatialDataProjectionType.utm,
                        [SpatialDataFormFieldNames.utmZone]: data.utmZones[0].value,
                        [SpatialDataFormFieldNames.utmZoneEPSG]: data.utmZones[0].epsg,
                        [SpatialDataFormFieldNames.country]: data.countries[0].value,
                        [SpatialDataFormFieldNames.coordinateSystem]:
                            data.countryCoordinateSystems[data.countries[0].value][0].value,
                        [SpatialDataFormFieldNames.epsg]:
                            data.countryCoordinateSystems[data.countries[0].value][0].epsg,
                    }}
                >
                    <div id="spatialDataInputs" className={styles.input_container}>
                        <Row className={styles.input_row}>
                            <Col>
                                <Form.Item
                                    name={SpatialDataFormFieldNames.projectionType}
                                    label={<FormattedMessage id="DataView.Upload.SpatialData.Upload.ProjectionType" />}
                                >
                                    <Radio.Group
                                        options={projectionTypeOptions}
                                        onChange={(e) => {
                                            setProjectionType(e.target.value);
                                            analytics.logUploadCadgisFile(UploadCadgisFileAction.selectProjectionType);
                                        }}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {projectionType === SpatialDataProjectionType.utm && (
                            <Row className={styles.input_row}>
                                <Col>
                                    <Form.Item
                                        name={SpatialDataFormFieldNames.utmZone}
                                        label={
                                            <FormattedMessage id="DataView.Upload.SpatialData.Upload.ProjectionType.UTMZone" />
                                        }
                                    >
                                        <Select
                                            showSearch
                                            options={data.utmZones}
                                            onSelect={(_: any, option: SpatialDataOption) => {
                                                analytics.logUploadCadgisFile(UploadCadgisFileAction.selectUtmZone);
                                                form.setFieldValue(SpatialDataFormFieldNames.utmZoneEPSG, option.epsg);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item hidden name={SpatialDataFormFieldNames.utmZoneEPSG}>
                                        <div />
                                    </Form.Item>
                                </Col>
                                <Col />
                            </Row>
                        )}
                        {projectionType === SpatialDataProjectionType.country && (
                            <>
                                <Row className={styles.input_row}>
                                    <Col>
                                        <Form.Item
                                            name={SpatialDataFormFieldNames.country}
                                            label={
                                                <FormattedMessage id="DataView.Upload.SpatialData.Upload.ProjectionType.Country" />
                                            }
                                        >
                                            <Select
                                                showSearch
                                                options={data.countries}
                                                onChange={(value) => {
                                                    analytics.logUploadCadgisFile(UploadCadgisFileAction.selectCountry);
                                                    const options: SpatialDataOption[] =
                                                        data.countryCoordinateSystems[value];
                                                    setCoordinateSystemOptions(options);
                                                    form.setFieldValue(
                                                        SpatialDataFormFieldNames.coordinateSystem,
                                                        options[0].value
                                                    );
                                                    form.setFieldValue(SpatialDataFormFieldNames.epsg, options[0].epsg);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            dependencies={[SpatialDataFormFieldNames.country]}
                                            label={
                                                <FormattedMessage id="DataView.Upload.SpatialData.Upload.CoordinateSystem" />
                                            }
                                        >
                                            {() => {
                                                return (
                                                    <Form.Item name={SpatialDataFormFieldNames.coordinateSystem}>
                                                        <Select
                                                            showSearch
                                                            options={coordinateSystemOptions}
                                                            onSelect={(_: any, option: SpatialDataOption) => {
                                                                analytics.logUploadCadgisFile(
                                                                    UploadCadgisFileAction.selectCoordinateSystem
                                                                );
                                                                form.setFieldValue(
                                                                    SpatialDataFormFieldNames.epsg,
                                                                    option.epsg
                                                                );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                );
                                            }}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className={styles.epsg_container}>
                                    <Form.Item dependencies={[SpatialDataFormFieldNames.coordinateSystem]}>
                                        {() => {
                                            return (
                                                <Form.Item name={SpatialDataFormFieldNames.epsg}>
                                                    <FormattedMessage
                                                        id="DataView.Upload.SpatialData.Upload.ESPG"
                                                        values={{
                                                            val: form.getFieldValue(SpatialDataFormFieldNames.epsg),
                                                        }}
                                                    />
                                                </Form.Item>
                                            );
                                        }}
                                    </Form.Item>
                                </Row>
                            </>
                        )}
                    </div>
                </Form>
            )}
        </StyledModal>
    );
};

export default SpatialDataUploadModal;
