import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProductCode } from '../../../types/proceq';
import {
    DEFAULT_PAGE_SIZE,
    MeasurementListItem,
    NonEditableMeasurements,
    productDataCategoryRoutes,
    ViewType,
} from '../../../types/measurement';
import { clearMeasurementList } from '../../../actions/measurement';
import { getMeasurementPath } from '../../Routes/urls';
import {
    MeasurementListParams,
    useCustomMaterialList,
    useMeasurementList,
    useMeasurementsPoll,
} from '../../../queries/measurementQueries';
import getIsMeasurementUnsynced from '../DataViewers/utils/getIsMeasurementUnsynced';
import { MeasurementContext, ViewModeConfig } from './MeasurementContext';
import { useDataViewContext } from '../DataViewProvider';

const filterUnsyncedMeasurements = (data: MeasurementListItem[]) =>
    data.filter((measurement) => getIsMeasurementUnsynced(measurement)).map((measurement) => measurement.id);

const DEFAULT_MEASUREMENT_PARAMS = {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    fileType: '',
    searchText: '',
    filterConfig: {},
};

export const MeasurementProvider: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    const dispatch = useDispatch();
    const { viewType, activeFolder, product, withUnsynced, dataType } = useDataViewContext();
    const [modalViewerConfig, setModalViewerConfig] = useState<ViewModeConfig>({});
    const [showHaze, setShowHaze] = useState(true);
    const [nonEditableMeasurements, setNonEditableMeasurements] = useState<undefined | NonEditableMeasurements>(
        undefined
    );
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const params = useParams<{ productName: string; viewType: ViewType; folderID: string }>();

    const [measurementListParams, setMeasurementListParams] = useState<MeasurementListParams>({
        ...DEFAULT_MEASUREMENT_PARAMS,
        product,
        withUnsynced,
        viewType,
        activeFolder,
    });

    // used to poll unsynced GM measurements
    const [unsyncedMeasurementIds, setUnsyncedMeasurementIds] = useState<string[]>([]);

    const openMeasurementInNewTab = (measurementID: string) => {
        const path = getMeasurementPath(measurementID);
        window.open(path, '_blank');
    };

    const resetConfig = useCallback(() => {
        setMeasurementListParams({ ...DEFAULT_MEASUREMENT_PARAMS, withUnsynced, viewType, product, fileType: '' });
    }, [product, viewType, withUnsynced]);

    useEffect(() => {
        resetConfig();
    }, [resetConfig, dataType]);

    useEffect(() => {
        // reset config when navigating to another data type
        if (productDataCategoryRoutes.some((category) => category === dataType)) {
            resetConfig();
            setMeasurementListParams((prevState) => ({ ...prevState, fileType: dataType }));
        } else {
            setMeasurementListParams((prevState) => {
                // if user is navigating from non-measurement related listing to measurement listing, reset the file type
                if (productDataCategoryRoutes.some((category) => category === prevState.fileType)) {
                    resetConfig();
                    return { ...prevState, fileType: '' };
                }
                return prevState;
            });
        }
    }, [resetConfig, dataType]);

    const {
        refetch: refetchMeasurement,
        isFetching: isFetchingMeasurement,
        data: measurementData,
    } = useMeasurementList(
        measurementListParams,
        dataType === measurementListParams.fileType ||
            !productDataCategoryRoutes.some((category) => category === params.folderID)
    );

    // remove unsynced measurement ids used for poll every time product or page changes
    useEffect(() => {
        setUnsyncedMeasurementIds([]);
    }, [product, measurementListParams.page]);

    const { data: polledMeasurements } = useMeasurementsPoll(
        {
            ...measurementListParams,
            mIDs: unsyncedMeasurementIds,
        },
        !isFetchingMeasurement && unsyncedMeasurementIds.length > 0 && product === ProductCode.GPR_MOUNTED
    );

    // get unsynced measurements to be used in poll. needs to be separated from polledMeasurements in case user refreshes data
    useEffect(() => {
        if (product === ProductCode.GPR_MOUNTED && measurementData?.data) {
            setUnsyncedMeasurementIds(filterUnsyncedMeasurements(measurementData.data));
        }
    }, [measurementData?.data, product]);

    // update unsynced measurements according to polled response
    useEffect(() => {
        if (product === ProductCode.GPR_MOUNTED && (polledMeasurements?.data ?? []).length > 0) {
            setUnsyncedMeasurementIds(filterUnsyncedMeasurements(polledMeasurements!.data));
        }
    }, [polledMeasurements, product]);

    const { refetch: refetchCustomMaterial, isFetching: isFetchingCustomMaterial } = useCustomMaterialList({
        pageSize: measurementListParams.pageSize,
        page: measurementListParams.page,
        fileType: measurementListParams.fileType,
        withUnsynced,
        orderConfig: measurementListParams.orderConfig,
        product,
    });

    const fetchMeasurementList = useCallback(
        async (changePage: boolean = false) => {
            if (product) {
                const response = await refetchMeasurement();
                setMeasurementListParams((prevState) => {
                    if (response.data?.data.length === 0 && changePage && prevState.page > 1) {
                        return { ...prevState, page: prevState.page - 1 };
                    }
                    return prevState;
                });
            }
        },
        [product, refetchMeasurement]
    );

    const fetchCustomMaterialList = useCallback(
        async (changePage: boolean = false) => {
            if (product) {
                const response = await refetchCustomMaterial();
                setMeasurementListParams((prevState) => {
                    if (response.data?.data.length === 0 && changePage && prevState.page > 1) {
                        return { ...prevState, page: prevState.page - 1 };
                    }
                    return prevState;
                });
            }
        },
        [product, refetchCustomMaterial]
    );

    useEffect(() => {
        if (product) {
            dispatch(clearMeasurementList({ product, folderID: activeFolder }));
        }
    }, [dispatch, activeFolder, product, measurementListParams.orderConfig, measurementListParams.searchText]);

    // default to first page when active folder changes
    useEffect(() => {
        setMeasurementListParams((prevState) => ({ ...prevState, page: 1, activeFolder }));
    }, [activeFolder]);

    return (
        <MeasurementContext.Provider
            value={{
                fetchMeasurementList,
                fetchCustomMaterialList,
                isFetching: isFetchingMeasurement || isFetchingCustomMaterial,
                modalViewerConfig,
                setModalViewerConfig,
                openMeasurementInNewTab,
                resetConfig,
                showHaze,
                setShowHaze,
                nonEditableMeasurements,
                setNonEditableMeasurements,
                selectedKeys,
                setSelectedKeys,
                measurementListParams,
                setMeasurementListParams,
            }}
        >
            {props.children}
        </MeasurementContext.Provider>
    );
};

export const useMeasurementContext = () => {
    return useContext(MeasurementContext);
};
