import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import {
    getASTMImperialDiameterRebarSize,
    PROFOMETER_METRIC_UNITS,
    ProfometerUnit,
} from '../../../../../types/measurement';
import { getUnitFormatID } from '../../Readings/Profometer/getMeasurementPresets';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { convertAndDisplay, roundToNDigit } from '../../utils/conversionRates';
import { ProductModel, ScanType } from '../../../../../types/proceq';
import ConvertedUnits from '../../shared/ConvertedUnits';
import StaticMap from '../StaticMap';
import { CORROSION_SCAN_TYPES, PROBE_TYPE_TEXT, ProbeType } from '../../../../../types/profometer';
import getPMSpotScanRebarSpacing from '../../utils/getPMSpotScanRebarSpacing';

const Creation: React.FunctionComponent<GPRLogbookEntryProps> = ({
    log,
    scanType,
    productModel,
    measurementSettings,
}) => {
    const { latitude, longitude, measurementInfo } = log.content;
    const coordinates = { lat: +latitude, lng: +longitude };
    const formatMessage = useFormatMessage();

    const {
        ambientTempInCelsius,
        ambientTempInFahrenheit,
        autoPotentialShift,
        autoSave,
        potentialShift,
        probeType,
        halfCellSolution,
        firstLayerRebarDiameter,
        secondLayerRebarDiameter,
        defaultBarDiameter,
        secondBarDiameter,
        neighboringBarCorrection,
        secondNeighboringBarCorrection,
        gridCellsX,
        gridCellsY,
        gridSpacingX,
        gridSpacingY,
        unit,
    } = measurementInfo;

    const isMetric = PROFOMETER_METRIC_UNITS.has(unit);

    const convert = (value: number, unitId: string) => {
        return isFinite(+value)
            ? convertAndDisplay(unitId, scanType ?? ScanType.LineScan, isMetric).convertFunction(+value)
            : '-';
    };

    const displayUnit =
        unit in ProfometerUnit ? formatMessage({ id: getUnitFormatID(unit as ProfometerUnit, scanType) }) : unit;
    const isCorrosion = scanType && CORROSION_SCAN_TYPES.has(scanType);
    const showGridDetails = scanType === ScanType.AreaScan || isCorrosion;
    const isPM8000 = productModel === ProductModel.PM8000;
    const gridUnitId = isPM8000 ? 'Profometer.Measurement.GridSize' : 'Profometer.Logbook.GridDimension';

    const scanDirection = isCorrosion
        ? measurementSettings?.content?.corrosionScanStatus?.areaScanParameters?.startDirection
        : measurementSettings?.content?.areaScanStatus?.areaScanParameters?.startDirection;
    const isHorizontalScanDirection = scanDirection ? scanDirection === 'X' : true;

    return (
        <div>
            <b>
                <FormattedMessage
                    id="Proceq.LogbookPropertyCreatedFileInFolder"
                    values={{
                        fileName: measurementInfo.fileName,
                        folderName: measurementInfo.folderName,
                    }}
                />
            </b>
            <br />
            <FormattedMessage id="Proceq.LogbookCreationMode" values={{ value: measurementInfo.mode }} />
            <br />
            <FormattedMessage id="Units" />
            {`: ${displayUnit}`}
            {scanType === ScanType.MultiLineScan && (
                <>
                    <br />
                    <ConvertedUnits
                        id="App.HTML.Profometer.MeasurementSettings.GridSetup.ColumnWidth"
                        unitId={gridUnitId}
                        scanType={scanType!}
                        isMetric={isMetric}
                    />
                    {`: ${convert(gridSpacingX, gridUnitId)}`}
                    <br />
                    <ConvertedUnits
                        id="App.HTML.Profometer.MeasurementSettings.GridSetup.RowHeight"
                        unitId={gridUnitId}
                        scanType={scanType!}
                        isMetric={isMetric}
                    />
                    {`: ${convert(gridSpacingY, gridUnitId)}`}
                </>
            )}
            {showGridDetails && (
                <>
                    <br />
                    <ConvertedUnits
                        id="App.HTML.Profometer.Logbook.GridDimension"
                        unitId={gridUnitId}
                        scanType={scanType!}
                        isMetric={isMetric}
                        values={
                            isHorizontalScanDirection
                                ? [gridCellsX * gridSpacingX, gridCellsY * gridSpacingY]
                                : [gridCellsY * gridSpacingY, gridCellsX * gridSpacingX]
                        }
                    />
                    <br />
                    <ConvertedUnits
                        id="App.HTML.Profometer.MeasurementSettings.GridSetup.CellDimensionX"
                        unitId={gridUnitId}
                        scanType={scanType!}
                        isMetric={isMetric}
                    />
                    {`: ${convert(isHorizontalScanDirection ? gridSpacingX : gridSpacingY, gridUnitId)}`}
                    <br />
                    <ConvertedUnits
                        id="App.HTML.Profometer.MeasurementSettings.GridSetup.CellDimensionY"
                        unitId={gridUnitId}
                        scanType={scanType!}
                        isMetric={isMetric}
                    />
                    {`: ${convert(isHorizontalScanDirection ? gridSpacingY : gridSpacingX, gridUnitId)}`}
                </>
            )}
            {isCorrosion ? (
                <>
                    <br />
                    <ConvertedUnits
                        id="App.HTML.Profometer.Logbook.ConcreteTemperatureUnits"
                        unitId="Profometer.Logbook.AmbientTemperature"
                        scanType={scanType!}
                        isMetric={isMetric}
                    />
                    {`: ${convert(isMetric ? ambientTempInCelsius : ambientTempInFahrenheit, 'Profometer.Logbook.AmbientTemperature')}`}
                    <br />
                    <FormattedMessage id="App.HTML.Profometer.Logbook.AutoPotentialShift" />
                    {': '} <FormattedMessage id={autoPotentialShift ? 'Proceq.On' : 'Proceq.Off'} />
                    <br />
                    <FormattedMessage id="App.HTML.Profometer.Logbook.PotentialShift" />
                    {`: ${roundToNDigit(potentialShift ?? 0, 0)}`}
                    <br />
                    <FormattedMessage id="App.HTML.FDL.IndicationInfo.ProbeType" />
                    {': '}
                    <FormattedMessage id={PROBE_TYPE_TEXT[probeType as ProbeType] ?? ' '} />
                    {probeType === ProbeType.ROD && (
                        <>
                            <br />
                            <FormattedMessage id="App.HTML.Profometer.Logbook.AutoSave" />
                            {': '}
                            <FormattedMessage id={autoSave ? 'Proceq.On' : 'Proceq.Off'} />
                        </>
                    )}
                    <br />
                    <FormattedMessage id="App.HTML.Profometer.Logbook.HalfCellSolution" />
                    {`: ${halfCellSolution}`}
                </>
            ) : (
                <>
                    {scanType === ScanType.SpotScan ? (
                        <>
                            <br />
                            <ConvertedUnits
                                id={
                                    isMetric
                                        ? 'App.HTML.Profometer.Logbook.DefaultRebarDiameterUnits'
                                        : 'App.HTML.Profometer.Logbook.DefaultRebarDiameter'
                                }
                                unitId="Profometer.Measurement.RebarDiameter"
                                scanType={scanType ?? ScanType.LineScan}
                                isMetric={isMetric}
                            />
                            {`: ${
                                !isMetric && defaultBarDiameter
                                    ? getASTMImperialDiameterRebarSize(defaultBarDiameter)
                                    : defaultBarDiameter
                            }`}
                            {secondBarDiameter !== undefined && (
                                <>
                                    <br />
                                    <ConvertedUnits
                                        id={
                                            isMetric
                                                ? 'App.HTML.Profometer.Logbook.RebarDiameter2Units'
                                                : 'App.HTML.Profometer.Logbook.RebarDiameter2'
                                        }
                                        unitId="Profometer.Measurement.RebarDiameter"
                                        scanType={scanType ?? ScanType.LineScan}
                                        isMetric={isMetric}
                                    />
                                    {`: ${
                                        !isMetric && secondBarDiameter
                                            ? getASTMImperialDiameterRebarSize(secondBarDiameter)
                                            : secondBarDiameter
                                    }`}
                                </>
                            )}
                            {neighboringBarCorrection !== undefined && (
                                <>
                                    <br />
                                    <ConvertedUnits
                                        id="App.HTML.Profometer.Logbook.RebarSpacingA1"
                                        unitId="Profometer.Logbook.RebarSpacing"
                                        scanType={scanType ?? ScanType.LineScan}
                                        isMetric={isMetric}
                                    />
                                    {`: ${getPMSpotScanRebarSpacing(isMetric, 'Profometer.Logbook.RebarSpacing', neighboringBarCorrection)}`}
                                </>
                            )}
                            {secondNeighboringBarCorrection !== undefined && (
                                <>
                                    <br />
                                    <ConvertedUnits
                                        id="App.HTML.Profometer.Logbook.RebarSpacingA2"
                                        unitId="Profometer.Logbook.RebarSpacing"
                                        scanType={scanType ?? ScanType.LineScan}
                                        isMetric={isMetric}
                                    />
                                    {`: ${getPMSpotScanRebarSpacing(isMetric, 'Profometer.Logbook.RebarSpacing', secondNeighboringBarCorrection)}`}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <br />
                            <ConvertedUnits
                                id="App.HTML.Profometer.MeasurementSettings.CoverOffset"
                                unitId="Profometer.Presets.CoverOffset"
                                scanType={scanType ?? ScanType.LineScan}
                                isMetric={isMetric}
                            />
                            {`: ${convert(measurementInfo.coverOffset, 'Profometer.Presets.CoverOffset')}`}
                            <br />
                            <ConvertedUnits
                                id={
                                    scanType === ScanType.LineScan || scanType === ScanType.MultiLineScan
                                        ? isMetric
                                            ? 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameterUnits.LineScan'
                                            : 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameter.LineScan'
                                        : isMetric
                                          ? 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameterUnits'
                                          : 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameter'
                                }
                                unitId="Profometer.Measurement.RebarDiameter"
                                scanType={scanType ?? ScanType.LineScan}
                                isMetric={isMetric}
                            />
                            {`: ${
                                !isMetric && firstLayerRebarDiameter
                                    ? getASTMImperialDiameterRebarSize(firstLayerRebarDiameter)
                                    : firstLayerRebarDiameter
                            }`}
                            <br />
                            <ConvertedUnits
                                id={
                                    scanType === ScanType.LineScan || scanType === ScanType.MultiLineScan
                                        ? isMetric
                                            ? 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameterUnits.LineScan'
                                            : 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameter.LineScan'
                                        : isMetric
                                          ? 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameterUnits'
                                          : 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameter'
                                }
                                unitId="Profometer.Measurement.RebarDiameter"
                                scanType={scanType ?? ScanType.LineScan}
                                isMetric={isMetric}
                            />
                            {`: ${
                                !isMetric && secondLayerRebarDiameter
                                    ? getASTMImperialDiameterRebarSize(secondLayerRebarDiameter)
                                    : secondLayerRebarDiameter
                            }`}
                            <br />
                            <FormattedMessage id="App.HTML.Profometer.MeasurementSettings.NeighboringRebarCorrection" />
                            {': '}
                            <FormattedMessage id={measurementInfo.nrc ? 'Proceq.On' : 'Proceq.Off'} />
                        </>
                    )}

                    <br />
                    <ConvertedUnits
                        id="App.HTML.Profometer.Logbook.MinimumCover"
                        unitId="Profometer.Logbook.MinimumCover"
                        scanType={scanType ?? ScanType.LineScan}
                        isMetric={isMetric}
                    />
                    {`: ${convert(measurementInfo.minimumCover, 'Profometer.Logbook.MinimumCover')}`}
                </>
            )}
            <StaticMap coordinates={coordinates} />
        </div>
    );
};

export default {
    element: Creation,
    color: () => 'green',
};
