import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserProperties, logEvent, Analytics, isSupported } from 'firebase/analytics';
import { isExternalUser } from 'tds-common-fe';
import config from '../config';
import {
    EventName,
    EventParameter,
    MeasurementPage,
    ProbeDetailsPage,
    SnapshotAction,
    DiscoveryCategory,
    PersonalProfileAction,
    MeasurementMenuCategory,
    PersonalProfileMenu,
    ShareLinkAction,
    ProductName,
    FolderManagementCategory,
    FileManagementCategory,
    ExportAction,
    SubscriptionAction,
    InsightsAction,
    SecurityAction,
    UploadCustomCurveAction,
    SnapshotModalAction,
    ImageSliderAction,
    ImageSliderCategory,
    UploadCadgisFileAction,
} from './analyticsConstants';

const standardiseProductName = (product: string) => {
    return ProductName[product] || product.toLowerCase();
};

enum FirebaseInstanceName {
    insights = 'insights',
    workspace = 'workspace',
}

const FIREBASE_CONFIGS: Record<FirebaseInstanceName, any> = {
    [FirebaseInstanceName.insights]: {
        apiKey: config.INSIGHTS_FIREBASE_API_KEY,
        authDomain: `${config.INSIGHTS_FIREBASE_PROJECT_ID}.firebaseapp.com`,
        databaseURL: `https://${config.INSIGHTS_FIREBASE_PROJECT_ID}.firebaseio.com`,
        projectId: config.INSIGHTS_FIREBASE_PROJECT_ID,
        storageBucket: `${config.INSIGHTS_FIREBASE_PROJECT_ID}.appspot.com`,
        messagingSenderId: config.INSIGHTS_FIREBASE_SENDER_ID,
        appId: config.INSIGHTS_FIREBASE_APP_ID,
        measurementId: `G-${config.INSIGHTS_FIREBASE_MEASUREMENT_ID}`,
    },
    [FirebaseInstanceName.workspace]: {
        apiKey: config.WORKSPACE_FIREBASE_API_KEY,
        authDomain: `${config.WORKSPACE_FIREBASE_PROJECT_ID}.firebaseapp.com`,
        databaseURL: `https://${config.WORKSPACE_FIREBASE_PROJECT_ID}.firebaseio.com`,
        projectId: config.WORKSPACE_FIREBASE_PROJECT_ID,
        storageBucket: `${config.WORKSPACE_FIREBASE_PROJECT_ID}.appspot.com`,
        messagingSenderId: config.WORKSPACE_FIREBASE_SENDER_ID,
        appId: config.WORKSPACE_FIREBASE_APP_ID,
        measurementId: `G-${config.WORKSPACE_FIREBASE_MEASUREMENT_ID}`,
    },
};

class FirebaseAnalytics {
    private instance?: Analytics;
    private insightsInstance?: Analytics;
    private setInsightsUserProperties: boolean = true;

    initializeInstance = async (instanceName: FirebaseInstanceName) => {
        const isFirebaseSupported = await isSupported();
        if (!isFirebaseSupported) {
            return;
        }
        if (instanceName === FirebaseInstanceName.workspace && this.instance === undefined) {
            this.instance = getAnalytics(initializeApp(FIREBASE_CONFIGS[instanceName]));
        }
        if (instanceName === FirebaseInstanceName.insights && this.insightsInstance === undefined) {
            this.insightsInstance = getAnalytics(initializeApp(FIREBASE_CONFIGS[instanceName], instanceName));
        }
    };

    // this is to initialize the default workspace instance
    initialize = async () => {
        await this.initializeInstance(FirebaseInstanceName.workspace);
        if (this.instance) {
            setUserProperties(this.instance, {
                app_version: config.APP_VERSION,
            });
        }
    };

    initializeInsightsInstance = async () => {
        await this.initializeInstance(FirebaseInstanceName.insights);
    };

    setUserProperty = (email: string) => {
        if (this.instance) {
            setUserProperties(this.instance, {
                is_external_user: isExternalUser(email),
            });
        }
    };

    logFirebaseEvent = ({
        instance,
        eventName,
        eventParams,
    }: {
        instance?: Analytics;
        eventName: string;
        eventParams?: { [_p: string]: any };
    }) => {
        if (instance) {
            logEvent(instance, eventName, eventParams);
        }
    };

    setCurrentScreen = (name: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: 'screen_view',
            eventParams: {
                firebase_screen: name,
                firebase_screen_class: undefined,
            },
        });
    };

    logClickHeaderLogo = () => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ClickHeaderLogo,
        });
    };

    logButtonClick = (buttonName: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ButtonClick,
            eventParams: {
                button_name: buttonName,
            },
        });
    };

    logSwitchProfileMenu = (category?: PersonalProfileMenu) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.SwitchProfileMenu,
            eventParams: {
                [EventParameter.category]: category,
            },
        });
    };

    logViewProfileMenu = (menuItem: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ViewProfileMenu,
            eventParams: { menu_item: menuItem },
        });
    };

    logSidebarSwitchProduct = (product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.SidebarSwitchProduct,
            eventParams: { [EventParameter.product]: standardiseProductName(product) },
        });
    };

    logMeasurementView = (page: MeasurementPage, product?: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ViewMeasurement,
            eventParams: {
                [EventParameter.page]: page,
                [EventParameter.product]: product ? standardiseProductName(product) : undefined,
            },
        });
    };

    logViewProbe = (page: ProbeDetailsPage) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ViewProbeDetails,
            eventParams: { [EventParameter.page]: page },
        });
    };

    logViewAllProbes = () => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ViewAllProbes,
        });
    };

    logViewDiscovery = (category: DiscoveryCategory, contentURL: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ViewDiscovery,
            eventParams: {
                [EventParameter.action]: category,
                content_url: contentURL,
            },
        });
    };

    logViewExportOptions = (page: MeasurementPage, product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ViewExportOptions,
            eventParams: {
                [EventParameter.page]: page,
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    logExportMeasurement = (
        page: MeasurementPage,
        product: string,
        format: string,
        fileCount: number,
        action: ExportAction,
        language?: string
    ) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ExportMeasurement,
            eventParams: {
                [EventParameter.page]: page,
                [EventParameter.product]: standardiseProductName(product),
                format,
                file_count: fileCount,
                file_language: language,
                [EventParameter.action]: action,
            },
        });
    };

    logViewLogbook = (product?: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ViewLogBook,
            eventParams: { [EventParameter.product]: product ? standardiseProductName(product) : undefined },
        });
    };

    logDownloadSnapshot = (action: SnapshotAction, product?: string, type?: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.DownloadSnapshot,
            eventParams: {
                [EventParameter.action]: action,
                [EventParameter.product]: product ? standardiseProductName(product) : undefined,
                type,
            },
        });
    };

    logDownloadAttachment = (product?: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.DownloadAttachment,
            eventParams: { [EventParameter.product]: product ? standardiseProductName(product) : undefined },
        });
    };

    logSortTable = (column: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.SortTable,
            eventParams: { column },
        });
    };

    logMeasurementMenu = (category: MeasurementMenuCategory) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ClickMeasurementMenu,
            eventParams: { [EventParameter.category]: category },
        });
    };

    logNonEditableMeasurement = (category: MeasurementMenuCategory | FileManagementCategory, product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.NonEditableMeasurement,
            eventParams: {
                [EventParameter.category]: category,
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    logInsightsAction = (action: InsightsAction) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.InsightsActions,
            eventParams: {
                [EventParameter.action]: action,
            },
        });
    };

    logFileManagement = (category: FileManagementCategory, product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.FileManagement,
            eventParams: {
                [EventParameter.category]: category,
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    logFolderManagement = (category: FolderManagementCategory, product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.FolderManagement,
            eventParams: {
                [EventParameter.category]: category,
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    logRefreshMeasurements = (product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.RefreshMeasurements,
            eventParams: { [EventParameter.product]: standardiseProductName(product) },
        });
    };

    logShareLinkEvent = (action: ShareLinkAction, product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ShareLink,
            eventParams: {
                [EventParameter.action]: action,
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    logPersonalProfileEvent = (action: PersonalProfileAction) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.EditPersonalProfile,
            eventParams: { [EventParameter.action]: action },
        });
    };

    logSubscriptionEvent = (action: SubscriptionAction, product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ViewSubscriptions,
            eventParams: {
                [EventParameter.action]: action,
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    logDeleteAccount = (product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.DeleteAccount,
            eventParams: {
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    // 2FA
    logProfileSecurity = (action: SecurityAction) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.EditSecurity,
            eventParams: { [EventParameter.action]: action },
        });
    };

    // insights logging
    logInsightsLicenseTier = (license: string) => {
        if (this.insightsInstance) {
            setUserProperties(this.insightsInstance, {
                license_tier: license,
            });
        }
    };

    logInsightsEvent = (
        event: string,
        email: string,
        appVersion: string,
        params?: {
            [key: string]: any;
        }
    ) => {
        if (this.setInsightsUserProperties) {
            if (this.insightsInstance) {
                setUserProperties(this.insightsInstance, {
                    is_external_user: isExternalUser(email),
                    app_version: appVersion,
                });
            }
            this.setInsightsUserProperties = false;
        }
        this.logFirebaseEvent({
            instance: this.insightsInstance,
            eventName: event,
            eventParams: params,
        });
    };

    // UT curve upload
    logUploadCustomCurve = (action: UploadCustomCurveAction, product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.UploadCustomCurve,
            eventParams: {
                [EventParameter.action]: action,
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    // spatial file upload
    logUploadCadgisFile = (action: UploadCadgisFileAction) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.uploadCadgisFile,
            eventParams: { [EventParameter.action]: action },
        });
    };

    // GM sync data
    logSyncMeasurement = (product: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.syncMeasurement,
            eventParams: { [EventParameter.product]: standardiseProductName(product) },
        });
    };

    logLaunchApp = (product: string, action: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.launchApp,
            eventParams: {
                [EventParameter.action]: action,
                [EventParameter.product]: standardiseProductName(product),
            },
        });
    };

    logSnapshotModal = (action: SnapshotModalAction, product?: string) => {
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.SnapshotModal,
            eventParams: {
                [EventParameter.action]: action,
                ...(product ? { [EventParameter.product]: standardiseProductName(product) } : {}),
            },
        });
    };

    logImageSliderAction = (params: { action: ImageSliderAction; product?: string; category: ImageSliderCategory }) => {
        const { action, product, category } = params;
        this.logFirebaseEvent({
            instance: this.instance,
            eventName: EventName.ImageSlider,
            eventParams: {
                [EventParameter.action]: action,
                [EventParameter.category]: category,
                ...(product ? { [EventParameter.product]: standardiseProductName(product) } : {}),
            },
        });
    };
}

const analytics = new FirebaseAnalytics();

export default analytics;
